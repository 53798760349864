import React from "react";
// import Plus from "../../../../src/assets/images/icons/plus.svg";
// import Setting from "../../../../src/assets/images/icons/setting.svg";
import ArrowLeftBlack from "../../../assets/images/icons/arrow_left_black.svg";

import { Link, useLocation } from "react-router-dom";
import InputField from "../../utils/forms/InputField";
// import { useState } from "react";
import { useForm } from "@kaydhiman/react-hook-useform";
function Header() {
  // const [key, setKey] = useState("liveclass");
  const location = useLocation();
  const { bindField } = useForm({
    validations: {},
  });
  return (
    <div className="row chat_header align-items-center">
      <div className="col-12 left_cont d-flex align-items-center chat_head_gap">
        <Link to="/">
          <img src={ArrowLeftBlack} alt="arrow left black" />
        </Link>
        {location.pathname.includes("contacts") && (
          <h2 className="ht mb-0">Contacts</h2>
        )}
        {location.pathname.includes("select-participants") && (
          <h2 className="ht mb-0">Select Participants</h2>
        )}
      </div>
      <InputField
        type="text"
        className="search_icon"
        placeholder="Search"
        bindField={bindField}
        name="searchBar"
      />
    </div>
  );
}

export default Header;
