import React from "react";

// import User from "../../../assets/images/icons/user.png";
// import ArrowLeftBlack from "../../../assets/images/icons/arrow_left_black.svg";
import { Link } from "react-router-dom";
import Header from "../header/Header";
import { ChatCard } from "../../utils/cards/ChatCard";
function SelectParticipants() {
  return (
    <div className="chat_box">
      <Header />
      <div className="chat_card_container bottombtn">
        <div className="card_head_tp">Add Participants</div>
        <div className="participant-listing">
          <ChatCard type={"SINGLE"} isHistory={false} />
        </div>
      </div>
      <div className="cus_btn_cont">
        <Link to="/newgroup">
          <button className="btn custBtn">Next</button>
        </Link>
      </div>
    </div>
  );
}

export default SelectParticipants;
