import Auth from "../auth/Auth";

export const userService = {
  emailValidation,
  login,
  createThreadMessage,
  createClassMessage,
  uploadAttachment,
  getClasses,
  getThread,
  getThreadMessage,
  getThreadUsersList,
  updateClassUserStatus,
  createClass,
  getImage,
  updateSettings,
  deleteClass,
  deleteClassMessage
};

function emailValidation(email) {
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return regex.test(email)
}

// login section

function login(params) {
  // let url = "http://psis.prathamedu.com/api/chat-app/login";   // old

  let url = "https://liveapi.prathamedu.com/api/chat-app/login";  // new
  return fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    method: "POST",
    body: JSON.stringify(params),
  });
}

function createClassMessage(params) {
  let url = `${process.env.REACT_APP_BASE_URL}/messages`;
  return fetch(url, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": String(Auth?.user()?.user_name),
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "POST",
    body: JSON.stringify(params?.body),
  });
}

function createThreadMessage(params) {
  let url = `${process.env.REACT_APP_BASE_URL}/threads/message`;
  return fetch(url, {
    headers: {
      // Authorization: Auth?.token(),
      "x-token": Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      // "x-token": String(Auth?.user()?.user_name),
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "POST",
    body: JSON.stringify(params?.body),
  });
}

function uploadAttachment(params) {

  let url = `${process.env.REACT_APP_BASE_URL}/storage`;
  return fetch(url, {
    headers: {
      'Content-Transfer-Encoding': 'binary',
      'Content-Disposition': `attachment; filename="${params?.name}"`,
      'Content-Type': params?.type,
      'x-entity': 'MESSAGE_ATTACHMENT',
      // "x-token": String(Auth?.user()?.user_name),
      "x-token": Auth?.token(),
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
      "x-thread-id": params?.threadId,
      // Authorization: Auth?.token(),
    },
    method: "PUT",
    body: params?.body,
  });

}

function getClasses(params) {
  let url = `${process.env.REACT_APP_BASE_URL}/threads${params?.history ? "/history" : ""
    }${params?.type ? "?type=" + params?.type : ""}${params?.from ? "&from=" + params?.from : ""}${params?.to ? "&to=" + params?.to : ""}`;
  return fetch(url, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": String(Auth?.user()?.user_name),
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "GET",
  });
}

function getThread(params) {
  let url = `${process.env.REACT_APP_BASE_URL}/threads/${params?.thread}`;
  emailValidation(String(Auth?.user()?.user_name))
  return fetch(url, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": String(Auth?.user()?.user_name),
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
      // "x-secret":"apisecret"
    },
    method: "GET",
  });
}

function getThreadMessage(thread) {
  return fetch(`${process.env.REACT_APP_BASE_URL}/messages?threadId=${thread}`, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "GET",
  });
}

function getThreadUsersList(thread) {
  return fetch(`${process.env.REACT_APP_BASE_URL}/threads/${thread}?with=students`, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "GET",
  });
}

function updateClassUserStatus(thread, student) {
  return fetch(`${process.env.REACT_APP_BASE_URL}/threads/${thread}/toggle/block`, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "PUT",
    body: JSON.stringify({ studentId: student.userId, block: student.is_block ? false : true })
  });
}



function createClass(params) {
  let url = `${process.env.REACT_APP_BASE_URL}/internal/threads/class`;
  return fetch(url, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "POST",
    body: JSON.stringify(params),
  });
};

function getImage(params) {
  let url = params?.url;
  emailValidation(String(Auth?.user()?.user_name))
  return fetch(url, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "GET",
  });
}

function updateSettings(thread, body) {
  let url = `${process.env.REACT_APP_BASE_URL}/threads/${thread}/settings`;
  return fetch(url, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "PATCH",
    body: JSON.stringify(body),
  });
};

function deleteClass(thread) {
  return fetch(`${process.env.REACT_APP_BASE_URL}/threads/${thread}`, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "DELETE",
  });
}

function deleteClassMessage(msgId, thread) {
  return fetch(`${process.env.REACT_APP_BASE_URL}/messages/${msgId}?thread=${thread}`, {
    headers: {
      // Authorization: Auth?.token(),
      "Content-Type": "application/json",
      Accept: "application/json",
      "x-token": Auth?.token()
      // "x-token": emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    },
    method: "DELETE",
  });
}
