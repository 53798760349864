import React from "react";
import { Outlet } from "react-router-dom";
import ChatContextProvider from "../../../shared/ChatContext";
import UserLive from "./UserLive";
function UserLayout() {
  return (
    <div className="container-fluid chat_containter">
      <div className="row d-flex">
        <div className="col-md-5 col-lg-6 col-xl-7 col-xxl-8  px-0">
          <UserLive />
        </div>
        <ChatContextProvider>
          <div className="col-md-7 col-lg-6 col-xl-5 col-xxl-4 px-0 right-sec">
            <Outlet />
          </div>
        </ChatContextProvider>
      </div>
    </div>
  );
}

export default UserLayout;
