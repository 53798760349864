import React from 'react'
import { Link } from 'react-router-dom';
import ArrowLeftBlack from "../../../assets/images/icons/arrow_left_black.svg";
import NewFile from "../../../assets/images/icons/new_file.svg";
import GroupNotiMode from "../../../assets/images/icons/groupNotiMode.svg";
import Lock from "../../../assets/images/icons/lock.svg";
function notificationsMode() {
  return (
  <>
   <div className="chat_box">
                <div className="row chat_header align-items-center">
                    <div className="col-12 left_cont d-flex align-items-center chat_head_gap">
                       <Link to="/setting"> <img src={ArrowLeftBlack} alt="arrow left black" /></Link>
                        <h2 className="ht mb-0">Notifications</h2>
                    </div>
                </div>
                <div className="chat_search">
        <input
          type="text"
          className="search_icon"
          placeholder="Search"
        />
      </div>
               <div className="chat_card_container mt-2t">
                <div className="chat_card chat_boxS chat_Pbt brd_white mt-1">
                    <div className="card_cont">
                        <div className="row">
                            <div className="col-8 d-flex align-items-center">
                                <div className="card_img">
                                    <img src={NewFile} alt="" className="small_user-icon" />
                                </div>
                                <div className="card_txt">
                                    <h4 className="ulive">New Text Message</h4>
                                    <h5 className="umgs mb-0">Anton Tkachev has send a mes…</h5>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="time_con text-end active_blue_txt">
                                    04 Apr, 2022
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chat_card chat_boxS chat_Pbt brd_white">
                    <div className="card_cont">
                        <div className="row">
                            <div className="col-8 d-flex align-items-center">
                                <div className="card_img">
                                    <img src={GroupNotiMode} alt="" className="small_user-icon" />
                                </div>
                                <div className="card_txt">
                                    <h4 className="ulive">New group</h4>
                                    <h5 className="umgs mb-0">You are added into a new group</h5>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="time_con text-end active_blue_txt">
                                    04 Apr, 2022
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chat_card chat_boxS chat_Pbt brd_white">
                    <div className="card_cont">
                        <div className="row">
                            <div className="col-8 d-flex align-items-center">
                                <div className="card_img">
                                    <img src={Lock} alt="" className="small_user-icon" />
                                </div>
                                <div className="card_txt">
                                    <h4 className="ulive">Password Change</h4>
                                    <h5 className="umgs mb-0">Your password has been changed..</h5>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="time_con text-end ">
                                    04 Apr, 2022
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
              
            </div>
  </>
  )
}

export default notificationsMode