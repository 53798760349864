// import logo from "./logo.svg";
import "./App.scss";
import LiveClass from "./components/layouts/liveclass/LiveClass";
import { Route, Routes, Navigate, HashRouter, BrowserRouter } from "react-router-dom";
// import StudentToStudent from "./components/layouts/chating/StudentToStudent";
// import StudentToClass from "./components/layouts/chating/StudentToClass";
// import StudentToGroup from "./components/layouts/chating/StudentToGroup";
import UserLayout from "./components/layouts/userlive/UserLayout";
import Chating from "./components/layouts/chating/Chating";
import SelectParticipants from "../src/components/layouts/liveclass/SelectParticipants";
import Setting from "./components/layouts/settingsmode/Setting";
import Contacts from "./components/layouts/liveclass/Contacts";
import NewGroup from "./components/layouts/liveclass/NewGroup";
import SettingMode from "./components/layouts/settingsmode/SettingMode";
import NotificationsMode from "./components/layouts/settingsmode/NotificationsMode";
import BlockUSerMode from "./components/layouts/settingsmode/BlockUSerMode";
import Report from "./components/layouts/reports/Report";
import Login from "./components/onboarding/Login";
import Toast from "./shared/toast/toast";
import Spinner from "./shared/spinner/spinner";
import { useContext } from "react";
import { AppContext } from "./shared/AppContext";
import Auth from "./auth/Auth";
import ChatHistory from "./components/layouts/settingsmode/ChatHistory";
function App() {
  const { spinner, toaster, setToaster } = useContext(AppContext);
  return (
    <>
      <HashRouter >
        {spinner && <Spinner />}

        <Toast
          show={toaster.show}
          content={toaster.content}
          type={toaster.type}
          onClose={() => {
            setToaster({
              show: false,
              type: "",
              content: "",
            });
          }}
          autoClose={toaster.autoClose || true}
        />
        <Routes>
          {Auth?.isUserAuthenticated() ? (
            <>
              <Route path="/" element={<UserLayout />}>
                <Route path="" element={<LiveClass />} />
                <Route
                  path="select-participants"
                  element={<SelectParticipants />}
                />
                <Route path="contacts" element={<Contacts />} />
                <Route path="newgroup" element={<NewGroup />} />
                <Route path="setting" element={<Setting />} />
                <Route path="setting/settingmode" element={<SettingMode />} />
                <Route path="setting/chat_history" element={<ChatHistory />} />
                <Route path="setting/chat_history/chatting/:thread" element={<Chating />} />

                <Route
                  path="setting/notifiactinmode"
                  element={<NotificationsMode />}
                />
                <Route path="setting/blockusers" element={<BlockUSerMode />} />
                <Route path="report" element={<Report />} />
                <Route path="chatting/:thread" element={<Chating />} />
                <Route path="chatting/:thread/blockusers" element={<BlockUSerMode />} />
                <Route path="chatting/:thread/unblockusers" element={<BlockUSerMode />} />


                {/* <Route
                  path="chatting/student-to-student"
                  element={<StudentToStudent />}
                /> */}
                {/* <Route
                  path="chatting/student-to-class"
                  element={<StudentToClass />}
                /> */}
                {/* <Route
                  path="chatting/student-to-group"
                  element={<StudentToGroup />}
                /> */}
              </Route>
            </>
          ) : (
            <>
              <Route path="*" element={<Navigate to="/" replace />} />
              <Route path="" element={<Login />} />
            </>
          )}
        </Routes>
      </HashRouter>
    </>
  );
}
export default App;
