import React from "react";
// import ChatUi from "../../../../src/assets/images/chatui.jpg";
import chatLogo from "../../../../src/assets/images/prathamLogo2x.png";

import ArrowLeftWhite from "../../../../src/assets/images/icons/arrow_left_white.svg";

function UserLive() {
  return (
    <div className="chat_box_ui position-relative left-section">
      {/* <img
        src={ChatUi}
        alt="user interface"
        className=" w-100 chat_box_uiImg"
      /> */}
      <div className="live_txt-con position-absolute d-flex align-items-center">
        <img src={ArrowLeftWhite} alt="arrow left" width="16" height="16" />
        <h4 className="live_text mb-0"> </h4>
      </div>
      <div className="logo-filed">
        <img src={chatLogo} alt="arrow left" />
      </div>
    </div>
  );
}

export default UserLive;
