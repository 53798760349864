import React, { useState, createContext } from "react";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  // SPINNER STATE
  const [spinner, setSpinner] = useState(false);
  const [dark_mode, setdark_mode] = useState();
  const [sidebarShow, setSidebarShow] = useState(false);
  const [formatedQuestions, setFormatedQuestion] = useState([]);
  const [index, setIndex] = useState(0);
  const [marksCount, setMarksCount] = useState(0)
  const [isChatHistory, setIsChatHistory] = useState(false);
  const [historyFromDate, setHistoryFromDate] = useState(new Date());
  const [historyToDate, setHistoryToDate] = useState(new Date());
  // TOASTER STATE
  const [toaster, setToaster] = useState({
    show: false,
    type: "", // type should be "danger" or "success"
    content: "",
  });

  return (
    <AppContext.Provider
      value={{
        spinner,
        setSpinner,
        toaster,
        setToaster,
        setSidebarShow,
        sidebarShow,
        dark_mode,
        setdark_mode,
        setFormatedQuestion,
        formatedQuestions,
        setIndex,
        index,
        setMarksCount,
        marksCount,
        isChatHistory, setIsChatHistory,
        historyFromDate, setHistoryFromDate,
        historyToDate, setHistoryToDate
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
