import moment from "moment";
import React, { useEffect, useRef } from "react";
import { Dropdown, Modal } from "react-bootstrap";

import placeholder from "../../../../assets/images/image-placeholder.jpg";
import pdfIcon from "../../../../assets/images/file.png";
import playIcon from "../../../../assets/images/play.svg";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import Auth from "../../../../auth/Auth";
import { ChatContext } from "../../../../shared/ChatContext";
import { userService } from "../../../../_services/User.services";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import { useState } from "react";
// import BlockBlue from "../../../../assets/images/icons/block_blue.svg";
import TreeDots from "../../../../assets/images/icons/tree_dots.svg";

// import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

import ReactPlayer from "react-player";
import { AppContext } from "../../../../shared/AppContext";

export default function MessageBox() {
  const {
    msgContent,
    setMsgContent,
    newMsgSend,
    setNewMsgSend,
    classBlockedUsers,
    setClassBlockedUsers,
    getThread,
    threadInfo
  } = useContext(ChatContext);

  const { isChatHistory } = useState(AppContext);
  // const location = useLocation()
  // const navigate = useNavigate();
  // const [blockedUser, setBlockedUser] = useState(location?.state?.blockedStudents || [])
  const [show, setShow] = useState(false);
  const { thread } = useParams();
  const chatWindow = useRef(null);
  const chatDrop = useRef();
  // const baseUrl = "http://43.204.232.170"; //old
  const baseUrl = "https://chat.prathamedu.com"; // new
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const loadPrivateImage = async (ref, url) => {
    if (ref.alt !== "img-loaded") {
      try {
        const imgResponse = await fetch(`${baseUrl}/api/storage/public/` + url, {
          headers: {
            "x-token": Auth?.token()
            // Authorization: Auth?.token(),
            // "x-token": String(Auth?.user()?.user_name),
          },
        });
        if (imgResponse.status === 200) {
          let body = await imgResponse.blob();
          ref.alt = "img-loaded";
          ref.src = URL.createObjectURL(body);
        } else {
          ref.alt = "img-loaded";
          ref.src = placeholder;
        }
      } catch (error) {
      }
    }
  };

  const imageBigSize = (event, type) => {
    Fancybox.show([
      {
        src: event.target.src,
        type: type,
      },
    ]);
  };

  // function emailValidation(email) {
  //   const regex =
  //     /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  //   return regex.test(email);
  // }

  // file load for audio,video file etc
  const fileLoad = async (event, msg, fileName = "") => {
    let apiUrl = `${baseUrl}/api/storage/public/` + msg?.meta?.attachment?.key;

    if (msg?.type === "MEDIA_VIDEO" || msg?.type === "MEDIA_AUDIO") {
      apiUrl = `${baseUrl}/api/messages/${msg?._id}/media/sign?thread=${thread}`;
    }
    try {
      const fileResponse = await fetch(apiUrl, {
        headers: {
          "x-token": Auth?.token(),
          // Authorization: Auth?.token(),
          // "x-token": emailValidation(String(Auth?.user()?.user_name))
          //   ? btoa(String(Auth?.user()?.user_name))
          //   : String(Auth?.user()?.user_name),
        },
        method: msg?.type === "ATTACHMENT" ? "GET" : "POST",
      });

      if (fileResponse.status === 200) {
        let url = "";
        if (msg?.type === "ATTACHMENT") {
          if (fileResponse?.url?.includes("pdf")) {
            url = window.URL.createObjectURL(
              msg?.type !== "ATTACHMENT"
                ? new Blob([await fileResponse.blob()])
                : new Blob([await fileResponse.blob()], {
                  type: "application/pdf",
                })
            );
          }

          if (fileResponse?.url?.includes("zip")) {
            url = window.URL.createObjectURL(
              msg?.type !== "ATTACHMENT"
                ? new Blob([await fileResponse.blob()])
                : new Blob([await fileResponse.blob()], {
                  type: "application/zip",
                })
            );
          }

          if (fileResponse?.url?.includes("docx")) {
            url = window.URL.createObjectURL(
              msg?.type !== "ATTACHMENT"
                ? new Blob([await fileResponse.blob()])
                : new Blob([await fileResponse.blob()], {
                  type: "application/docx",
                })
            );
          }

          if (msg?.type === "ATTACHMENT") {
            const a = document.createElement("a");
            a.setAttribute("download", fileName);
            a.setAttribute("target", "_blank");
            a.href = url;
            a.click();
          }
        } else if (msg?.type === "MEDIA_VIDEO") {
          fileResponse.json().then((urlId) => {
            setShowVideoModal(true);
            setVideoUrl(
              `${baseUrl}/api/links/${urlId.token}?signableId=${msg?._id}`
            );
            // Fancybox.show([
            //   {
            //     src: `${baseUrl}/links/${urlId.token}?signableId=${msg?._id}`,
            //     type: 'video',
            //     // controlsList: "nodownload"
            //   },
            // ]);
          });
        } else if (msg?.type === "MEDIA_AUDIO") {
          return new Promise((resolve, reject) => {
            fileResponse.json().then((urlId) => {
              if (urlId) {
                resolve(
                  `${baseUrl}/api/links/${urlId.token}?signableId=${msg?._id}`
                );
              }
            });
          });
        }
      }
    } catch (error) {
    }
  };

  const convertToLocalmessageDate = (date) => {
    let local = moment.utc(date).local().format("DD/MM/YYYY HH:mm A");
    return local;
  };

  const nameFormating = (f, l) => {
    let name = f + " " + l;
    if (name.length > 15) {
      name = f + " ...";
    }
    return name;
  };

  const getMessageByThread = async () => {
    try {
      const response = await userService?.getThreadMessage(thread);
      response.json().then((res) => {
        let newList = res.messages.map((ele) => {
          let newObj = Object.assign({}, ele);
          if (newObj.type === "TEXT") {
            newObj.isTextUrl = checkTextUrl(newObj.content);
          }
          if (newObj.type === "MEDIA_AUDIO") {
            const demo = fileLoad("", newObj);
            demo.then((url) => {
              newObj.audioUrl = url;
            });
          }
          return newObj;
        });
        // setMsgContent(res.messages)
        setMsgContent(newList);
        if (res?.status === 401) {
          localStorage.clear();
        };
      });
    } catch (error) {
    }
  };

  const changeUserStatus = async (user) => {
    let body = {};
    // let isBlock = false;

    if (classBlockedUsers?.blockedStudents?.includes(user?.sender?.userId)) {
      body = { userId: user?.sender?.userId, is_block: true };
      // const response = await userService?.updateClassUserStatus(thread, { userId: user?.senderUserId, is_block: 1 });

      let filter = classBlockedUsers?.blockedStudents?.filter(
        (itm) => itm !== user?.sender?.userId
      );
      setClassBlockedUsers((pre) => {
        return { ...pre, blockedStudents: filter };
      });
      // setBlockedUser(filter)
      // { studentId: student.userId, block: student.is_block ? false : true }
      // isBlock = false;
    } else {
      body = { userId: user?.sender?.userId, is_block: false };
      // const response = await userService?.updateClassUserStatus(thread, { userId: user?.senderUserId, is_block: 0 });

      let newArr = [...classBlockedUsers.blockedStudents];
      newArr.push(user?.sender.userId);
      setClassBlockedUsers((pre) => {
        return { ...pre, blockedStudents: newArr };
      });
      // setBlockedUser(prev => [...prev, user?.sender.userId])
    }
    try {
      const response = await userService?.updateClassUserStatus(thread, body);
      if (response.status >= 200 && response.status <= 299) {
        getThread();
        alert(
          `${user?.sender?.fname} ${body.is_block ? "unblocked" : "blocked"
          } successfully.`
        );
      };
      if (response.status >= 400 && response.status <= 499) {
        // const data = await response.json();

      };
      if (response?.status === 401) {
        localStorage.clear();
      };
      chatDrop.current.classList.remove("show");

    } catch (error) {
    }
  };
  // [userId, is_block]
  // const delet = async (user, index) => {

  //   try {
  //     const response = await userService?.updateClassUserStatus(thread, { userId:});

  //     // const newArr = [...usersList];
  //     // newArr[index].is_block = user.is_block ? 0 : 1;
  //     // setUsersList(newArr);

  //   }
  //   catch (error) {
  //   }
  // }

  const deleteMessage = async (msgId, thread) => {
    await userService
      .deleteClassMessage(msgId, thread)
      .then((ele) => {
        setMsgContent((preState) => {
          return preState.filter((ele) => ele._id !== msgId);
        });
        chatDrop.current.classList.remove("show");
      })
      .catch((err) => {
      });
  };

  const checkTextUrl = (text, ev = "") => {
    const pattern =
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

    if (ev && ev === "click") {
      if (pattern.test(text)) {
        window.open(text, "_blank");
        return;
      }
      return;
    }

    if (pattern.test(text)) {
      return true;
    }
    return false;
  };


  // ************************for scrolling to the latest message**************************
  useEffect(() => {
    const element = document.getElementById(`msg-${msgContent?.length - 1}`);
    element?.scrollIntoView();
    console.log(element, msgContent, "==============element=======msgContent=======");
  }, [msgContent])

  useEffect(() => {
    getMessageByThread();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (newMsgSend) {
      getMessageByThread();
      setNewMsgSend(false);
    }
    //eslint-disable-next-line
  }, [newMsgSend]);

  return (
    <div ref={chatWindow} className="chat-logs">
      <div className="row">
        {msgContent?.length > 0 &&
          msgContent?.map((msg, index) => {
            return (
              <React.Fragment key={"msg" + index}>
                {msg?.content !== " " && msg?.content !== "" && (
                  <div className={`row`} id={`msg-${index + 1}`}>
                    {/* <div className={`${msg?.content?.length > 35 ? 'col-md-10' : 'col-md-8'}  ${msg.senderUserRole === 'TEACHER' ? ((msg?.senderUserId) === btoa(String(Auth?.user()?.user_name)) ? (msg?.content.length > 35 ? 'offset-2' : 'offset-4') + ' self_people' : 'user_people') : (msg?.senderUserId) == Auth?.user()?.user_name ? (msg?.content.length > 35 ? 'offset-2' : 'offset-4') + ' self_people' : 'user_people'}`}> */}
                    <div
                      className={`${msg?.content?.length > 35 ? "col-lg-10" : "col-lg-8"
                        } ${msg.senderUserRole === "TEACHER"
                          ? msg?.senderUserId ===
                            btoa(String(Auth?.user()?.user_name))
                            ? (msg?.content.length > 35
                              ? "offset-lg-2"
                              : "offset-lg-4") + " self_people"
                            : "user_people"
                          : msg?.senderUserId == Auth?.user()?.user_name
                            ? (msg?.content.length > 35
                              ? "offset-lg-2"
                              : "offset-lg-4") + " self_people"
                            : "user_people"
                        } `}
                    >

                      <div
                        className={`chat-msg group_uname_box ovHidUnset ${msg?.senderUserRole === "TEACHER" &&
                          Auth?.user().role === "student"
                          ? "highlightDiv"
                          : ""
                          }`}
                      // className={`${Auth?.user().role === "Teacher"
                      //    ? "chat-msg_teacher group_uname_box ovHidUnset"
                      //    : "highlightDiv"
                      //    }`}
                      >
                        <div
                          title={msg?.sender?.fname + " " + msg?.sender?.lname}
                          className="group_uname"
                          style={{ color: "white" }}
                        // {...((msg?.senderUserRole === "STUDENT" && Auth?.user().role === 'Teacher') && { onClick: () => changeUserStatus(msg) })}
                        >
                          <div
                            className="chat-header-content"
                            style={{ minWidth: "70%", textAlign: "left" }}
                          >
                            {nameFormating(
                              msg?.sender?.fname,
                              msg?.sender?.lname
                            )}{" "}
                            {msg?.senderUserRole === "TEACHER"
                              ? "(Faculty)"
                              : `(${msg?.senderUserId})`}
                          </div>

                          {/* msg remove or block user */}
                          <div
                            className="chat-detail-button"
                            style={{ minWidth: "30%", textAlign: "right" }}
                          >
                            {isChatHistory === false && (msg?.senderUserId ===
                              btoa(String(Auth?.user()?.user_name)) ||
                              msg?.senderUserId == Auth?.user()?.user_name ||
                              Auth.user().role !== "student") && (
                                <div className="">
                                  <Dropdown
                                    autoClose="outside"
                                    className="cust_dropdwonStyle"
                                    drop="start"
                                  >
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      className="bg-transparent"
                                    >
                                      <img
                                        src={TreeDots}
                                        alt="dots icon"
                                        className="dotsThree_iconS"
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                      ref={chatDrop}
                                      style={{ width: "120px" }}
                                    >
                                      {Auth.user().role !== "student" &&
                                        msg?.senderUserId !==
                                        btoa(
                                          String(Auth?.user()?.user_name)
                                        ) && (
                                          <Dropdown.Item
                                            eventKey="1"
                                            onClick={() => changeUserStatus(msg)}
                                          >
                                            {
                                              // classBlockedUsers?.blockedStudents?.indexOf(msg?.senderUserId) === -1
                                              threadInfo?.blockedStudents?.indexOf(msg?.senderUserId) === -1
                                                ? `Block User`
                                                : `Unblock User`}
                                          </Dropdown.Item>
                                        )}

                                      {(msg?.senderUserId ===
                                        btoa(String(Auth?.user()?.user_name)) ||
                                        msg?.senderUserId ==
                                        Auth?.user()?.user_name ||
                                        Auth.user().role === "Teacher") && (
                                          <Dropdown.Item
                                            eventKey="2"
                                            onClick={() =>
                                              deleteMessage(msg._id, msg.threadId)
                                            }
                                          >
                                            Remove
                                          </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              )}
                          </div>
                          {/* msg remove or block user */}
                        </div>
                        <div
                          className="cm-msg-text"
                          style={{
                            whiteSpace: "break-spaces",
                            wordBreak: "break-word",
                          }}
                        >
                          {msg.isTextUrl ? (
                            <div >
                              {msg?.content?.split(" ").map((ele, index) => {
                                return (
                                  <>
                                    <div key={index + 1} onClick={() => {
                                      if (ele?.includes("youtube") || ele?.includes("vimeo")) {
                                        setVideoUrl(ele);
                                        setShowVideoModal(true)
                                      } else {
                                        checkTextUrl(ele, "click");
                                      }

                                    }}
                                    >
                                      <span
                                        key={`key-${index}`}

                                        className={checkTextUrl(ele) ? "urlSpan" : ""}
                                      // style={{ zIndex: "1" }}
                                      >
                                        {ele + " "}
                                        {/* <a href={ele + " "} disabled={true}> {ele + " "} </a> */}



                                      </span>
                                    </div>
                                  </>


                                )
                              })}{" "}
                            </div>
                          ) : (
                            msg?.content
                          )}
                        </div>
                      </div>
                      <div className="time_con">
                        {convertToLocalmessageDate(msg?.createdAt)}
                      </div>
                    </div>
                  </div>
                )}
                {/* For Attachment Message Show */}
                {msg?.meta?.attachment && (
                  <div className={`row`}>
                    <div
                      className={`${msg?.type === "MEDIA_AUDIO" ||
                        msg?.type === "ATTACHMENT"
                        ? " col-md-9"
                        : " col-md-6"
                        } ${msg.senderUserRole === "TEACHER"
                          ? msg?.senderUserId ===
                            btoa(String(Auth?.user()?.user_name))
                            ? (msg.type === "MEDIA_AUDIO" ||
                              msg?.type === "ATTACHMENT"
                              ? "offset-3"
                              : "offset-6") + " self_people"
                            : "user_people"
                          : msg?.senderUserId == Auth?.user()?.user_name
                            ? (msg.type === "MEDIA_AUDIO" ||
                              msg?.type === "ATTACHMENT"
                              ? "offset-3 "
                              : "offset-6 ") + " self_people"
                            : "user_people"
                        }`}
                    >
                      <div
                        className={`chat-msg group_uname_box ovHidUnset  ${msg?.senderUserRole === "TEACHER" &&
                          Auth?.user().role === "student"
                          ? "highlightDiv"
                          : ""
                          }`}
                      >
                        <div className="group_uname" style={{ color: "white" }}>
                          <div
                            className="chat-header-content"
                            style={{ minWidth: "70%", textAlign: "left" }}
                          >
                            {nameFormating(
                              msg?.sender?.fname,
                              msg?.sender?.lname
                            )}{" "}
                            {msg?.senderUserRole === "TEACHER"
                              ? "(Facuilty)"
                              : `(${msg?.senderUserId})`}
                          </div>

                          {/* msg remove or block user */}
                          <div
                            className="chat-detail-button"
                            style={{ minWidth: "30%", textAlign: "right" }}
                          >
                            {isChatHistory === false && (msg?.senderUserId ===
                              btoa(String(Auth?.user()?.user_name)) ||
                              msg?.senderUserId == Auth?.user()?.user_name ||
                              Auth.user().role !== "student") && (
                                <div className="">
                                  <Dropdown
                                    autoClose="outside"
                                    className="cust_dropdwonStyle"
                                    drop="start"
                                  // style={{ float: "right" }}
                                  >
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      className="bg-transparent"
                                    >
                                      <img
                                        src={TreeDots}
                                        alt="dots icon"
                                        className="dotsThree_iconS"
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                      ref={chatDrop}
                                      style={{ width: "120px" }}
                                    >
                                      {Auth.user().role !== "student" &&
                                        msg?.senderUserId !==
                                        btoa(
                                          String(Auth?.user()?.user_name)
                                        ) && (
                                          <Dropdown.Item
                                            eventKey="1"
                                            onClick={() => changeUserStatus(msg)}
                                          >
                                            {
                                              // classBlockedUsers?.blockedStudents?.indexOf(msg?.senderUserId) === -1
                                              threadInfo?.blockedStudents?.indexOf(msg?.senderUserId) === -1
                                                ? `Block User`
                                                : `Unblock User `}
                                          </Dropdown.Item>
                                        )}

                                      {(msg?.senderUserId ===
                                        btoa(String(Auth?.user()?.user_name)) ||
                                        msg?.senderUserId ==
                                        Auth?.user()?.user_name ||
                                        Auth.user().role === "Teacher") && (
                                          <Dropdown.Item
                                            eventKey="2"
                                            onClick={() =>
                                              deleteMessage(msg._id, msg.threadId)
                                            }
                                          >
                                            Remove
                                          </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              )}
                          </div>
                          {/* msg remove or block user */}
                        </div>
                        <div className="cm-msg-text">
                          {msg?.type === "ATTACHMENT" && (
                            <>
                              <img
                                className="pdf-icon"
                                src={pdfIcon}
                                width="70px"
                                height={"70px"}
                                title={msg?.meta?.attachment}
                                alt=""
                                onClick={() =>
                                  fileLoad(
                                    "",
                                    msg,
                                    typeof msg?.meta?.attachment === "string"
                                      ? msg?.meta?.attachment
                                      : msg?.meta?.attachment.name
                                  )
                                }
                              />
                              <span
                                className="attachment-title"
                                style={{ wordBreak: "break-word" }}
                                onClick={() =>
                                  fileLoad(
                                    "",
                                    msg,
                                    typeof msg?.meta?.attachment === "string"
                                      ? msg?.meta?.attachment
                                      : msg?.meta?.attachment.name
                                  )
                                }
                              >
                                {typeof msg?.meta?.attachment === "string"
                                  ? msg?.meta?.attachment
                                  : msg?.meta?.attachment.name}
                              </span>
                            </>
                          )}
                          {msg?.type === "MEDIA_IMAGE" && (
                            <img
                              onLoad={(event) =>
                                loadPrivateImage(
                                  event.target,
                                  typeof msg?.meta?.attachment === "string"
                                    ? msg?.meta?.attachment
                                    : msg?.meta?.attachment.key
                                )
                              }
                              src={placeholder}
                              // width="100px"
                              // height="100px"
                              alt=""
                              className="chat-img"
                              onClick={(event) => imageBigSize(event, "image")}
                            />

                            // <Lightbox image={msg?.meta?.attachment?.name} title="Image Title" />


                          )}
                          {msg?.type === "MEDIA_VIDEO" && (
                            <div className="videoThumb">
                              <img
                                onLoad={(event) =>
                                  loadPrivateImage(
                                    event.target,
                                    msg?.meta?.thumbnail?.key
                                  )
                                }
                                // src={`${process.env.PUBLIC_URL}/video-placeholder.webp`}
                                src={placeholder}
                                // height="120px"
                                alt=""
                                className="chat-img videoImg"
                              />
                              <img
                                className="playIc"
                                src={playIcon}
                                onClick={(event) => fileLoad(event, msg)}
                                alt=""
                              />
                            </div>
                          )}
                          {msg?.type === "MEDIA_AUDIO" && (
                            <div style={{ overflow: "hidden" }}>
                              <audio
                                onLoad={(event) => fileLoad(event, msg)}
                                controlsList="nodownload"
                                src={msg.audioUrl}
                                controls
                                style={{
                                  width: "270px",
                                }}
                              ></audio>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="time_con">
                        {convertToLocalmessageDate(msg?.createdAt)}
                      </div>
                    </div>

                  </div>
                )}
              </React.Fragment>
            );
          })}
        <p id="hihi"></p>
      </div>
      {/* <Modal show={show} className="modalclass"
        onHide={() => setShow(false)}
        contentClassName="hello"
        dialogClassName="custom_modal"
        backdropClassName="backgdo"
        centered >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>

      </Modal> */}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        centered
        className="modalclass"
      >
        <Modal.Body>
          <div className="row sure-delete">
            <h2>
              Are you sure,
              <br />
              you want to delete this University?
            </h2>
            <div className="row delete-btn">
              <button className={`ml-3 btn mx-0`}>Yes</button>

              <button className={`btn-light ml-3`}>Yes</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showVideoModal}
        onHide={() => setShowVideoModal(false)}
        centered
        className=" video-modal"
      // close={() => setShowVideoModal(false)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ReactPlayer
            url={videoUrl}
            playing={true}
            controls={true}
            playIcon={true}
            width="100%"
            height="100%"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
