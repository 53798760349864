import moment from "moment";
import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Modal } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import User from "../../../../src/assets/images/icons/user.png";
import Auth from "../../../auth/Auth";
import { ChatContext } from "../../../shared/ChatContext";
import { userService } from "../../../_services/User.services";
import deleteIcon from "../../../assets/images/icons/delete_black.svg"
import crossIcon from "../../../assets/images/cross.jpg"
import { AppContext } from "../../../shared/AppContext";

export function ChatCard({ type, liveClasses, setLiveClasses, isHistory }) {

  const location = useLocation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { setToaster } = useContext(AppContext)
  const { setMsgContent, classBlockedUsers, setClassBlockedUsers } = useContext(ChatContext);
  const [deleteClassId, setDeleteClassId] = useState();
  // const [liveClasses, setLiveClasses] = useState([]);

  // const getClasses = async () => {
  //   const response = await userService?.getClasses({ type: type })
  //   if (response?.status >= 200 && response?.status <= 299) {
  //     const data = await response.json();
  //     setLiveClasses(data?.threads);
  //   }
  // };

  const deleteClass = async () => {
    if (deleteClassId) {
      await userService.deleteClass(deleteClassId).then(res => {
        setLiveClasses(preState => {
          return preState.filter(ele => ele._id !== deleteClassId);
        });
        setDeleteClassId();
        setShowModal(false);
      }).catch(err => {
      });
    }
  }

  const changeRoute = (elem) => {
    if (elem.blockedStudents.indexOf(String(Auth?.user().user_name)) === -1) {
      setClassBlockedUsers({ classId: elem?._id, blockedStudents: elem?.blockedStudents });
      navigate(`chatting/${elem?._id}?type=${type}`, { state: { type: type, blockedStudents: elem?.blockedStudents } });
      return;
    }
    setToaster({ show: true, type: "danger", content: 'Oops! you did a mistake. You are blocked, talk to your service manager for immediate help.', });
    // alert('Oops! you did a mistake. You are blocked, talk to your service manager for immediate help.');
  }

  // const searchClass = (value) => {
  //   if (liveClasses?.length) {
  //     let filterData = liveClasses?.filter(itm => itm?.name?.includes(value));
  //
  //   }
  // }

  // useEffect(() => {
  //   getClasses();
  //   //eslint-disable-next-line
  // }, []);


  const handleLiveClass = (elem) => {
    if (moment(new Date()).isBetween(elem?.startAt, elem?.endAt)) {
      changeRoute(elem);
    } else {
      setToaster({ show: true, type: "danger", content: `Please wait... Class will be live on ${moment(new Date(elem?.startAt)).format("DD-MM-YYYY hh:mm A")}` });
    }

    if (moment(new Date()).isAfter(elem?.endAt)) {
      setToaster({ show: true, type: "danger", content: `Class has been expired at ${moment(elem?.endAt).format('HH:MM A')}` });
    }

  }

  return (

    <React.Fragment>
      <div className="chat_card_container liveClass_viewH">
        {liveClasses?.length > 0 &&
          liveClasses?.map((elem, index) => {

            return (
              <div
                className={`chat_card chat_boxS chat_Pbt ${moment(new Date()).isBetween(elem?.startAt, elem?.endAt) ? "brd_unread" : ""}`}
                key={`key-${index + 1}`}
                onClick={() => {
                  if (isHistory) {
                    navigate(`/setting/chat_history/chatting/${elem?._id}?type=${type}`, { state: { type: type, blockedStudents: elem?.blockedStudents } });
                  } else {
                    handleLiveClass(elem)
                  }

                  setMsgContent([]);
                  // moment(new Date()).isBetween(elem?.startAt, elem?.endAt) ?
                  //   changeRoute(elem) :
                  //   setToaster({ show: true, type: "danger", content: `Please wait..., Class will be live on ${moment(new Date(elem?.startAt)).format("DD-MM-YYYY hh:mm A")}` });
                  // alert(`Please wait..., Class will be live on ${moment(new Date(elem?.startAt)).format("DD-MM-YYYY hh:mm A")}`)
                }}
              >
                <div className="card_cont"
                // onClick={() => {
                //   moment(new Date()).isBetween(elem?.startAt, elem?.endAt) ?
                //     changeRoute(elem) : alert(`Please wait..., Class will be live on ${moment(new Date(elem?.startAt)).format("DD-MM-YYYY hh:mm A")}`)
                // }}
                >
                  {/* <Link to={`chatting/${elem?._id}`} state={{ type: type }}> */}
                  <div className="row" >
                    <div className="col-8 d-flex align-items-center" >
                      <div className="card_img">
                        <img src={User} alt="" className={`small_user-icon ${moment(new Date()).isBetween(elem?.startAt, elem?.endAt) && 'active-dot-icon-img'}`} />
                        {moment(new Date()).isBetween(elem?.startAt, elem?.endAt) && <div className="active-dot-icon"></div>}
                      </div>
                      <div className="card_txt">
                        <h4 className="ulive">
                          {elem?.name}
                        </h4>
                        {type === "CLASS" && (
                          <h5 className="uname mb-0">
                            {/* {elem?.lastMessage?.senderUserId} */}
                          </h5>
                        )}
                        {type === "GROUP" && (
                          <h5 className="umgs mb-0">
                            <span>Stian</span>That’s great
                          </h5>
                        )}
                        {type === "SINGLE" && (
                          <h5 className="umgs mb-0">
                            I hope you like crazy Alien movies!!!
                          </h5>
                        )}
                      </div>
                    </div>
                    <div className="col-4 d-flex justify-content-end align-items-center flex-wrap">
                      <div className="time_con text-end">
                        {moment(elem?.createdAt).format("DD MMM, YYYY")}
                      </div>
                      {/* {Auth?.user()?.role === 'Teacher' && <img class="delCls" src={deleteIcon} alt="deleteIcon" onClick={() => { setShowModal(true); setDeleteClassId(elem._id) }} />} */}
                    </div>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
            );
          })}
        {location.pathname.includes("contacts") && (
          <div className="chat_card chat_boxS chat_Pbt brd_white">
            <div className="card_cont">
              <div className="row">
                <div className="col-12 d-flex align-items-center">
                  <div className="card_img">
                    <img src={User} alt="" className="small_user-icon" />
                  </div>
                  <div className="card_txt">
                    <h4 className="ulive mb-0">
                      Tiger Queen
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* {location.pathname.includes("select-participants") && (
          <div className="chat_card chat_boxS chat_Pbt brd_white">
            <div className="card_cont">
              <div className="row">
                <div className="col-10 d-flex align-items-center">
                  <div className="card_img">
                    <img src={User} alt="" className="small_user-icon" />
                  </div>
                  <div className="card_txt">
                    <h4 className="ulive mb-0">Live Class 1</h4>
                  </div>
                </div>
                <div className="col-2 d-flex align-items-center justify-content-end">
                  <div className="checkbox-group">
                    <label className="check-container">
                      <input type="checkbox" style={{ display: "none" }} />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} 
        */}
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        className="modalclass"
      >

        <Modal.Body>
          <div className="modContent">
            <div className="crossBtn" >
              <img src={crossIcon} alt="" onClick={() => { setShowModal(false); setDeleteClassId() }} />
            </div>
            <div className="modText">
              <h2>
                Are you sure,
                <br />
                you want to delete this Class?
              </h2>
            </div>

            <div className="modBtn text-center">
              <button
                className="btnDel"
                onClick={() => deleteClass()} >
                Yes
              </button>

              <button
                className="btnNo"
                onClick={() => { setShowModal(false); setDeleteClassId() }} >
                No
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>

    </React.Fragment>
  );
}
