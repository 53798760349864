import React from 'react'
import DeleteIcon from "../../../assets/images/icons/delete.svg";
import User from "../../../assets/images/icons/user.png";
import Camera from "../../../assets/images/icons/camera.svg";
import ArrowLeftBlack from "../../../assets/images/icons/arrow_left_black.svg";
import { Link } from 'react-router-dom';
function NewGroup() {
  return (
   <>
      <div className="chat_box">
                <div className="row chat_header align-items-center">
                    <div className="col-12 left_cont d-flex align-items-center chat_head_gap">
                       <Link to="/"> <img src={ArrowLeftBlack} alt="arrow left black" /></Link>
                        <h2 className="ht mb-0">New Group</h2>
                    </div>
                </div>
                <div className="chat_search d-flex align-items-center add_camera_search">
                    <div className="camera_icon">
                        <img src={Camera} alt="camera icon" />
                    </div>
                    <input type="text" className="search_icon" placeholder="Enter Group name here…" />
                </div>
               <div className="chat_card_container bottombtn">
                   <div className="card_head_tp">
                    Add Participants: 
                   </div>
                <div className="chat_card chat_boxS chat_Pbt brd_white">
                    <div className="card_cont">
                        <div className="row">
                            <div className="col-10 d-flex align-items-center">
                                <div className="card_img">
                                    <img src={User} alt="" className="small_user-icon" />
                                </div>
                                <div className="card_txt">
                                    <h4 className="ulive mb-0">Live Class 1</h4>
                                </div>
                            </div>
                            <div className="col-2 d-flex align-items-center justify-content-end">
                               <div className="delete_icon">
                                   <img src={DeleteIcon} alt="delete icon" className="cursor_pointer" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chat_card chat_boxS chat_Pbt brd_white">
                    <div className="card_cont">
                        <div className="row">
                            <div className="col-10 d-flex align-items-center">
                                <div className="card_img">
                                    <img src={User} alt="" className="small_user-icon" />
                                </div>
                                <div className="card_txt">
                                    <h4 className="ulive mb-0">Live Class 1</h4>
                                </div>
                            </div>
                            <div className="col-2 d-flex align-items-center justify-content-end">
                               <div className="delete_icon">
                                   <img src={DeleteIcon} alt="delete icon" className="cursor_pointer" />
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
               </div>
               <div className="cus_btn_cont">
                <button className="btn custBtn">Next</button>
               </div>
            </div>
   </>
  )
}

export default NewGroup