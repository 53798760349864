import React from 'react'
import ArrowLeftBlack from "../../../assets/images/icons/arrow_left_black.svg";
import { Link } from 'react-router-dom';

import Auth from '../../../auth/Auth';

function SettingMode() {
    const user = Auth.user();
    return (
        <>
            <div className="chat_box">
                <div className="row chat_header align-items-center">
                    <div className="col-12 left_cont d-flex align-items-center chat_head_gap">
                        <Link to="/setting"> <img src={ArrowLeftBlack} alt="arrow left black" /></Link>
                        <h2 className="ht mb-0">Settings</h2>
                    </div>
                </div>

                <div className="chat_card_container mt-2t">

                    <div className="chat_card chat_boxS chat_Pbt brd_white mt-1">
                        <div className="card_cont">
                            <div className="row">
                                <div className="col-8 d-flex align-items-center">
                                    <div className="card_txt">
                                        <h4 className="ulive mb-0">Push Notifications</h4>
                                    </div>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-end">
                                    <div className="toggle_txt active_blue_txt">
                                        On
                                    </div>
                                    <div className="toggle-group">
                                        <label className="switch">

                                            <input type="checkbox" style={{ display: "none" }} />
                                            <span className="togg-slide togg-round"></span>

                                        </label>

                                    </div>
                                    <div className="toggle_txt">
                                        Off
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {user.role !== 'student' &&
                        <>

                            <div className="chat_card chat_boxS chat_Pbt brd_white">
                                <div className="card_cont">
                                    <div className="row">
                                        <div className="col-8 d-flex align-items-center">
                                            <div className="card_txt">
                                                <h4 className="ulive mb-0">Chat Mode</h4>
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                            <div className="toggle_txt active_blue_txt">
                                                All
                                            </div>
                                            <div className="toggle-group">
                                                <label className="switch">

                                                    <input type="checkbox" style={{ display: "none" }} />
                                                    <span className="togg-slide togg-round"></span>

                                                </label>

                                            </div>
                                            <div className="toggle_txt">
                                                Self
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="chat_card chat_boxS chat_Pbt brd_white">
                                <div className="card_cont">
                                    <div className="row">
                                        <div className="col-8 d-flex align-items-center">
                                            <div className="card_txt">
                                                <h4 className="ulive mb-0">File Sharing</h4>
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                            <div className="toggle_txt active_blue_txt">
                                                On
                                            </div>
                                            <div className="toggle-group">
                                                <label className="switch">

                                                    <input type="checkbox" style={{ display: "none" }} />
                                                    <span className="togg-slide togg-round"></span>

                                                </label>

                                            </div>
                                            <div className="toggle_txt">
                                                Off
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="chat_card chat_boxS chat_Pbt brd_white">
                                <div className="card_cont">
                                    <div className="row">
                                        <div className="col-8 d-flex align-items-center">
                                            <div className="card_txt">
                                                <h4 className="ulive mb-0">Mute Notifications</h4>
                                            </div>
                                        </div>
                                        <div className="col-4 d-flex align-items-center justify-content-end">
                                            <div className="toggle_txt active_blue_txt">
                                                On
                                            </div>
                                            <div className="toggle-group">
                                                <label className="switch">

                                                    <input type="checkbox" style={{ display: "none" }} />
                                                    <span className="togg-slide togg-round"></span>

                                                </label>

                                            </div>
                                            <div className="toggle_txt">
                                                Off
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }


                </div>

            </div>
        </>
    )
}

export default SettingMode