import React, { useContext, useEffect } from "react";

import Dropdown from "react-bootstrap/Dropdown";

import User from "../../../assets/images/icons/user.png";
import ArrowLeftBlack from "../../../assets/images/icons/arrow_left_black.svg";
import TreeDots from "../../../assets/images/icons/tree_dots.svg";
// import NotAllowed from "../../../assets/images/icons/notAllowed.svg";
// import DeleteBlack from "../../../assets/images/icons/delete_black.svg";
// import MuteVol from "../../../assets/images/icons/MuteVol.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
// import Auth from "../../../auth/Auth";
import CommonModal from "../../utils/modal/CommonModal";
import { userService } from "../../../_services/User.services";
import { ChatContext } from "../../../shared/ChatContext";
import Auth from "../../../auth/Auth";
import BlockBlue from "../../../assets/images/icons/block_blue.svg";

function ChatHeader() {
  const [deleteModal, setDeleteModal] = useState(false);

  const navigate = useNavigate();
  const { threadInfo, setthreadInfo, seconds, setSeconds } = useContext(ChatContext);
  const chatTimeIntervals = JSON.parse(localStorage.getItem("chatTimeIntervals"));

  const deleteModalClose = () => {
    navigate("/chatting");
    setDeleteModal(false);
  };

  const { thread } = useParams();

  const getThread = async () => {
    const response = await userService?.getThread({ thread: thread });

    if (response?.status >= 200 && response?.status <= 299) {
      const data = await response.json();

      setthreadInfo(data?.thread);
    };
    if (response?.status === 401) {
      localStorage.clear();
    };


  };
  const settingUpdateHandler = async (event) => {

    const { name, checked } = event.target;

    let body = {

      ...(((threadInfo?.enableFileSharing === true || threadInfo?.enableFileSharing === false) && name === "enableFileSharing") ? { [name]: checked } : { enableFileSharing: threadInfo?.enableFileSharing }),
      ...(((threadInfo?.enableHistory === true || threadInfo?.enableHistory === false) && name === "enableHistory") ? { [name]: checked } : { enableHistory: threadInfo?.enableHistory }),
      ...(((threadInfo?.enableStudentChat === true || threadInfo?.enableStudentChat === false) && name === "enableStudentChat") ? { [name]: checked } : { enableStudentChat: threadInfo?.enableStudentChat }),
      ...(((threadInfo?.studentChatPause >= 0) && name === "studentChatPause") ? { [name]: checked } : { studentChatPause: threadInfo?.studentChatPause > 0 ? true : false }),
    };
    // 
    const response = await userService?.updateSettings(thread, body);
    if (response?.status >= 200 && response?.status <= 299) {
      // const data = await response.json();
      getThread();
    };
    if (response?.status === 401) {
      localStorage.clear();
    };
  };

  const handleTime = () => {
    let thredIndx = chatTimeIntervals?.findIndex(ele => ele?.id === thread);

    if (thredIndx && threadInfo?.studentChatPause && thredIndx !== -1) {
      chatTimeIntervals[thredIndx].time = seconds;
      localStorage.setItem("chatTimeIntervals", JSON?.stringify(chatTimeIntervals));
    }
  };

  useEffect(() => {
    getThread();
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row chat_header align-items-center">
        <div className="col-sm-11 col-10 left_cont d-flex align-items-center chat_head_gap">
          {/* <Link to="/"> */}
          <img src={ArrowLeftBlack} alt="arrow left black" onClick={() => {
            handleTime();
            navigate(-1)
          }} />
          {/* </Link> */}
          <div className="head_profile_icon">
            <img src={User} alt="" className="small_user-icon" />
          </div>
          <h2 className="ht mb-0 overFtxt">{threadInfo?.name}</h2>
        </div>

        {Auth.user().role !== 'student' && <div className="col-sm-1 col-2 right_cont d-flex justify-content-end pr-4 align-items-start">
          <Dropdown
            autoClose="outside"
            className="cust_dropdwonStyle ">
            <Dropdown.Toggle
              id="dropdown-basic"
              className="bg-transparent"
            >
              <img src={TreeDots} alt="dots icon" className="dotsThree_iconS" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item>
                <Link to="/report" className="d-flex align-items-center">
                  <div className="dropdown_itemIcon">
                    <img src={NotAllowed} alt="not allowed" />
                  </div>
                  Report
                </Link>
              </Dropdown.Item> */}

              {Auth.user().role !== 'student' && <Dropdown.Item>
                <Link to={`/chatting/${thread}/blockusers`} state={{ mode: "block" }} className="d-flex align-items-center">
                  <div className="dropdown_itemIcon">
                    <img src={BlockBlue} alt="not allowed" />
                  </div>
                  Block Users
                </Link>
              </Dropdown.Item>
              }
              {/* {Auth.user().role !== 'student' && <Dropdown.Item>
                <Link to={`/chatting/${thread}/unblockusers`} state={{ mode: "unblock" }} className="d-flex align-items-center">
                  <div className="dropdown_itemIcon">
                    <img src={BlockBlue} alt="not allowed" />
                  </div>
                  Unblocked Users
                </Link>
              </Dropdown.Item>
              } */}

              {/* <div className="chat_card chat_boxS chat_Pbt brd_white">
                  <Link to="blockusers">
                    <div className="card_cont">
                      <div className="row">
                        <div className="col-12 d-flex align-items-center">
                          <div className="card_img">
                            <img
                              src={BlockBlue}
                              alt="notification"
                              className="small_iconSett"
                            />
                          </div>
                          <div className="card_txt">
                            <h4 className="ulive mb-0"></h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div> */}

              {/* <Dropdown.Item
                as="button"
                className="d-flex align-items-center"
                onClick={deleteModal}
              >
                <div className="dropdown_itemIcon">
                  <img src={DeleteBlack} alt="block black" />
                </div>
                Delete Group
              </Dropdown.Item> */}

              {Auth.user().role !== 'student' && <><Dropdown.Item
                as="button"
                className="d-flex align-items-center">
                <div className="toggle-group">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="studentChatPause"
                      style={{ display: "none" }}
                      onChange={(event) => settingUpdateHandler(event)}
                      checked={threadInfo?.studentChatPause}
                    />
                    <span className="togg-slide togg-round"></span>
                    <span className="drop-text">Slow Mode</span>
                  </label>
                </div>
              </Dropdown.Item>

                <Dropdown.Item
                  as="button"
                  className="d-flex align-items-center">
                  <div className="toggle-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="enableFileSharing"
                        onChange={(event) => settingUpdateHandler(event)}
                        style={{ display: "none" }}
                        checked={threadInfo?.enableFileSharing}
                      />
                      <span className="togg-slide togg-round"></span>
                      <span className="drop-text"> File Sharing</span>
                    </label>
                  </div>
                </Dropdown.Item>

                {/* <Dropdown.Item
                  as="button"
                  className="d-flex align-items-center">
                  <div className="toggle-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="enableHistory"
                        onChange={(event) => settingUpdateHandler(event)}
                        style={{ display: "none" }}
                        checked={threadInfo?.enableHistory}
                      />
                      <span className="togg-slide togg-round"></span>
                      <span className="drop-text">Chat History</span>
                    </label>
                  </div>
                </Dropdown.Item> */}

                <Dropdown.Item
                  as="button"
                  className="d-flex align-items-center">
                  <div className="toggle-group">
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="enableStudentChat"
                        onChange={(event) => settingUpdateHandler(event)}
                        style={{ display: "none" }}
                        checked={threadInfo?.enableStudentChat}
                      />
                      <span className="togg-slide togg-round"></span>
                      <span className="drop-text"> Allow Student Chat</span>
                    </label>
                  </div>
                </Dropdown.Item>
              </>}

            </Dropdown.Menu>
          </Dropdown>

          {/* <Dropdown className="cust_dropdwonStyle">
            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
              <span className="user-img">
                <span className="arrowicon">
                  <img src="assets/images/setting.png" alt="setting" className="clickrot-arrow" />
                </span>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <label>Change Password</label>
              </Dropdown.Item>
              <Dropdown.Item>
                <label>Notification</label>
              </Dropdown.Item>
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              <Dropdown.Item>
                <label>Contact Us</label>
              </Dropdown.Item>
              <Dropdown.Item>
                <label>Filter</label>
              </Dropdown.Item>
              <Dropdown.Item>
                <label>FAQ</label>
              </Dropdown.Item>
              <Dropdown.Item>
                <label>Rate &amp; Feedback</label>
              </Dropdown.Item>
              <Dropdown.Item>
                <label>About MyVille</label>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}

        </div>
        }
      </div>

      <CommonModal
        show={deleteModal}
        close={deleteModalClose}
        // className={dark_mode}
        body={
          <div className="common_modal pb-5 text-center">
            <div className="modal-body ">
              <p>Do you want to delete this group</p>
              <div className="modal-btns"></div>
            </div>
          </div>
        }
      />
    </>
  );
}

export default ChatHeader;
