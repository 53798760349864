import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from "@kaydhiman/react-hook-useform";

import logo from "../../assets/images/prathamLogo2x.png";
import passwordShow from "../../assets/images/eye.png";
import passwordHide from "../../assets/images/eye02.png";
import studentIcon from "../../assets/images/student.png";
import teacherIcon from "../../assets/images/teacher.png";

import LabeledInputField from "../utils/forms/LabeledInputField";
import { useState } from "react";
import Auth from "../../auth/Auth";
import { AppContext } from "../../shared/AppContext";
import { userService } from "../../_services/User.services";

function Login() {
  const { bindField, values } = useForm({
    validations: {},
  });
  const [showHidePassword, setShowHidePassword] = useState(false);
  const route = useNavigate();
  const { setSpinner, setToaster } = useContext(AppContext);
  const [roleSelect, setRoleSelect] = useState(0);

  //------------------------Login Api-----------------------------
  const loginApi = async (e) => {
    setSpinner(true);
    e.preventDefault();

    let body = {
      username: values.userName,
      password: values.password,

      ...(roleSelect > 0
        ? { role: roleSelect === 1 ? "teacher" : "student" }
        : ""),
      api_token: "",
    };

    const response = await userService?.login(body)


    if (response?.status >= 200 && response?.status <= 299) {
      const data = await response.json();
      localStorage.setItem("body", JSON.stringify(body))

      if (data.__token !== null) {
        Auth.login(data);
      }
      route("/");

      setToaster({
        show: true,
        type: data.__token !== null ? "success" : "danger",
        content: data.message,
      });

      setTimeout(() => {
        setSpinner(false);
      }, 100);
    }
    if (response?.status === 401) {
      localStorage.clear();
    };
  };

  return (
    <div className="chat-onboarding">
      <div className="container">
        <div className="row">
          <div className="col-md-6 middle ">
            {/* <div className="onboarding-heading">
              <h3>Admin Login</h3>
            </div> */}
            <div className="form-area">
              <div className="onboarding-box">
                <div className="logo-area  text-center">
                  <img src={logo} alt="" />
                </div>

                <div className="form-area">
                  <form onSubmit={loginApi}>
                    <div className=" form-group">
                      <div className="input-comp">
                        <label className="form-label" required>
                          Select the role <span className="required">*</span>
                        </label>
                        {/* <select name="role" className="coupon-type-select">
                          <option value="">Select</option>
                          <option value="admin">Admin</option>
                          <option value="subAdmin">Student</option>
                        </select> */}
                        <div className="role-selector d-flex align-items-center">
                          <div
                            className="role-selector"
                            onClick={() => setRoleSelect(1)}
                          >
                            <LabeledInputField
                              type="radio"
                              label="Teacher"
                              id="teacher"
                              name="isStudent"
                              bindField={bindField}
                              radioChecks={{
                                src: teacherIcon,
                                login: true,
                              }}
                              value={values?.isStudent || "1"}
                            // checked={values?.isStudent === "1"}
                            />
                          </div>
                          <div
                            className="role-selector"
                            onClick={() => setRoleSelect(2)}
                          >
                            <LabeledInputField
                              type="radio"
                              label="Student"
                              id="student"
                              name="isStudent"
                              bindField={bindField}
                              radioChecks={{
                                src: studentIcon,
                                login: true,
                              }}
                              value={values?.isStudent || "0"}
                            // checked={values?.isStudent === "0"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <LabeledInputField
                        label=" User Name"
                        type="text"
                        id="name"
                        placeholder="User Name"
                        name="userName"
                        bindField={bindField}
                        // className="form-control "
                        required
                      />
                    </div>
                    <div className="password_form">
                      <LabeledInputField
                        label="Password"
                        id="password"
                        // className="form-control "
                        name="password"
                        placeholder="Enter your password"
                        type={showHidePassword ? "text" : "password"}
                        iconData={{
                          className: "lock_ico pointer",
                          showpass: true,
                          src: showHidePassword ? passwordShow : passwordHide,
                          alt: "Password icon",
                        }}
                        value={values.password}
                        showHidePassword={showHidePassword}
                        setShowHidePassword={setShowHidePassword}
                        bindField={bindField}
                        required
                      />
                    </div>
                    <div className="onboarding-btn-area submit_button">
                      {/* <Button
                        type="submit"
                        className="send_content btn mx-0 w-100"
                        disabled=""
                        to="home"
                        value="Login"
                      /> */}
                      <button
                        type="submit"
                        className="send_content btn mx-0 w-100"
                        disabled=""
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
