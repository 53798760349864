import React from "react";

import "./spinner.scss";

function spinner() {
  return (
    <>
      <div className="spin-wrapper">
        <div className="spinner"></div>
      </div>
    </>
  );
}

export default spinner;
