import React from "react";

import "./toast.scss";

function Toast({ show, type, content, onClose, autoClose, duration }) {
  if (autoClose) {
    if (show) {
      setTimeout(() => onClose(), duration ? +duration : 3000);
    }
  }
  return (
    <>
      {show && (
        <div
          onClick={onClose}
          className={`alert alert-${type} ${show ? "toast-show" : ""}`}
        >
          <h4 className="alert-heading">
            {type === "danger" ? "Error" : "Success"}!
          </h4>
          <hr />
          <p>{content}</p>
        </div>
      )}
    </>
  );
}
export default Toast;
