class Auth {
  authenticated = false;

  login(data) {
    console.log('logData:', data);
    // new Change
    const token = data.data.role === 'Teacher' ? `${btoa(data.data.email)}|${data.__token}` :
      `${data.data.student_data[0].enrolment_id}|${data.__token}`;
    localStorage.setItem("_prathamChatUserToken", token);
    const user = JSON.stringify(data?.data);
    localStorage.setItem("userInfo", user);
    if (token) {
      this.authenticated = true;
    } else {
      this.authenticated = false;
    }
  }

  token() {
    let token = localStorage.getItem("_prathamChatUserToken");
    return token;
  }

  user() {
    let user = localStorage.getItem("userInfo");
    return JSON.parse(user);
  }

  logout() {
    // localStorage.removeItem("_prathamChatUserToken");
    // localStorage.removeItem("studentInfo");
    localStorage.clear();
    this.authenticated = false;
  }

  isUserAuthenticated() {
    if (localStorage.getItem("_prathamChatUserToken")) {
      return true;
    } else {
      return false;
    }
  }

  //*****************************// Exam SECTION //******************************//

  setExamData(data) {
    const examData = JSON.stringify(data);
    localStorage.setItem("examData", examData);
  }

  getExamData() {
    let examdata = localStorage.getItem("examData");
    return JSON.parse(examdata);
  }

  //*****************************// BREAD CRUMB SECTION //******************************//
  setBreadCrumbData(data) {
    sessionStorage.setItem("breadCrumbData", JSON.stringify(data));
  }

  setHeaderTitle(title) {
    sessionStorage.setItem("headerName", JSON.stringify(title));
  }

  emptySession() {
    sessionStorage.clear();
  }

  breadData() {
    return {
      breadData: JSON.parse(sessionStorage.getItem("breadCrumbData")),
      headerName: JSON.parse(sessionStorage.getItem("headerName")),
    };
  }
}

export default new Auth();
