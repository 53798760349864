import React, { useContext, useEffect, useRef } from "react";
import ChatHeader from "./ChatHeader";
import MessageBox from "../../utils/cards/chatComponents/MessageBox";
import MessageSendBox from "../../utils/cards/chatComponents/MessageTextBox";
import { ChatContext } from "../../../shared/ChatContext";
import { AppContext } from "../../../shared/AppContext";
import { useLocation } from "react-router-dom";



function Chating() {
  const { msgContent, } = useContext(ChatContext);
  const { isChatHistory } = useContext(AppContext)
  const ref = useRef()
  const location = useLocation()

  // useEffect(() => {
  //   ref.current.parentElement.scrollTop = ref.current.parentElement.scrollHeight;
  //   console.log("((((ref.current.parentElement.scrollTop", ref.current.parentElement.scrollTop);
  // }, [msgContent])

  return (
    <div className="chat_box">
      <ChatHeader />
      <div className="chat_box_body" ref={ref} >
        <MessageBox />
        {(!location?.pathname?.includes("chat_history")) && <MessageSendBox />}
      </div>
    </div>
  );
}

export default Chating;
