import React from "react";
export default function InputField({
  type,
  className,
  placeholder,
  value,
  disable,
  id,
  name,
  label,
  htmlFor,
  iconData,
  showHidePassword,
  setShowHidePassword,
  bindField,
  required,
  //   login,
  radioChecks,
  checked,
}) {
  return (
    <>
      <div className="input-box mb-5 mt-4">
        <input
          type={type}
          name={name}
          id={id}
          value={value}
          {...bindField(name)}
          disabled={disable}
          placeholder={placeholder}
          className={`roleSelector ${className ? className : ""}`}
          checked={checked}
        />
      </div>
    </>
  );
}
