import React from "react";
import { Modal } from "react-bootstrap";

// import crossIcon from "../../../assets/images/cross.svg";

export default function CommonModal({ show, close, heading, body, className }) {
  return (
    <>
      <div className="my_modal">
        <Modal
          show={show}
          centered
          className={`${className ? className : ""} `}
        >
          <Modal.Header>
            {/* <span className="close-btn float-end " role="button">
              <img src={crossIcon} alt="" onClick={close} />
            </span> */}
            <Modal.Title>{heading}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{body}</Modal.Body>
        </Modal>
      </div>
    </>
  );
}
