import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeftBlack from "../../../assets/images/icons/arrow_left_black.svg";
import SettingBlue from "../../../assets/images/icons/setting_blue.svg";
import NotificationBlue from "../../../assets/images/icons/notification_blue.svg";
import clock from "../../../assets/images/clock.svg";
import LogoutIcon from "../../../assets/images/icons/logout.svg";
import { useContext } from "react";
import { AppContext } from "../../../shared/AppContext";
import Auth from "../../../auth/Auth";

function Setting() {
  const { setSpinner } = useContext(AppContext);
  const navigate = useNavigate();

  const user = Auth.user();

  const logOut = () => {
    setSpinner(true);
    Auth.logout();
    setTimeout(() => {
      setSpinner(false);
    }, 100);
  };

  return (
    <>
      <div className="chat_box">
        <div className="row chat_header align-items-center">
          <div className="col-12 left_cont d-flex align-items-center chat_head_gap">
            <Link to="/">
              <img src={ArrowLeftBlack} alt="arrow left black" />
            </Link>
            <h2 className="ht mb-0">Settings</h2>
          </div>
        </div>

        <div className="chat_card_container mt-2t">

          {user.role !== 'student' &&
            <>
              <div className="chat_card chat_boxS chat_Pbt brd_white mt-1">
                <Link to="settingmode">
                  <div className="card_cont">
                    <div className="row">
                      <div className="col-12 d-flex align-items-center">
                        <div className="card_img">
                          <img
                            src={SettingBlue}
                            alt=""
                            className="small_iconSett"
                          />
                        </div>
                        <div className="card_txt">
                          <h4 className="ulive mb-0">Settings</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="chat_card chat_boxS chat_Pbt brd_white">
                <Link to="notifiactinmode">
                  <div className="card_cont">
                    <div className="row">
                      <div className="col-12 d-flex align-items-center">
                        <div className="card_img">
                          <img
                            src={NotificationBlue}
                            alt="notification"
                            className="small_iconSett"
                          />
                        </div>
                        <div className="card_txt">
                          <h4 className="ulive mb-0">Notifications</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
              {/* <div className="chat_card chat_boxS chat_Pbt brd_white">
                <Link to="blockusers">
                  <div className="card_cont">
                    <div className="row">
                      <div className="col-12 d-flex align-items-center">
                        <div className="card_img">
                          <img
                            src={BlockBlue}
                            alt="notification"
                            className="small_iconSett"
                          />
                        </div>
                        <div className="card_txt">
                          <h4 className="ulive mb-0">Blocked Users</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div> */}
            </>
          }
          <div className="chat_card chat_boxS chat_Pbt brd_white" onClick={() => { navigate("/setting/chat_history") }}>
            <div className="card_cont cursor" >
              <div className="row">
                <div className="col-12 d-flex align-items-center ">
                  <div className="card_img">
                    <img
                      src={clock}
                      alt="notification"
                      className="small_iconSett"
                    />
                  </div>
                  <div className="card_txt">
                    <h4 className="ulive mb-0">Chat History</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="chat_card chat_boxS chat_Pbt brd_white">
            <div className="card_cont cursor" onClick={logOut}>
              <div className="row">
                <div className="col-12 d-flex align-items-center ">
                  <div className="card_img">
                    <img
                      src={LogoutIcon}
                      alt="notification"
                      className="small_iconSett"
                    />
                  </div>
                  <div className="card_txt">
                    <h4 className="ulive mb-0">Log Out</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}

export default Setting;
