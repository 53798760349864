// import { useForm } from "@kaydhiman/react-hook-useform";
import React from "react";
// import InputField from "./InputField";
export default function LabeledInputField({
  type,
  className,
  placeholder,
  value,
  disable,
  id,
  name,
  label,
  htmlFor,
  iconData,
  showHidePassword,
  setShowHidePassword,
  bindField,
  required,
  //   login,
  radioChecks,
  checked,
}) {
  return (
    <>
      <div className="inputField">
        <label
          className={`form-label  ${className ? className : " "}`}
          htmlFor={id}
        >
          <span className="label-filed">{label}</span>
          {required ? <span className="required">*</span> : ""}
          <input
            type={type}
            name={name}
            id={id}
            value={value}
            {...bindField(name)}
            disabled={disable}
            placeholder={placeholder}
            className={`roleSelector ${className ? className : ""}`}
            checked={checked}
          />
          {iconData?.showpass && value && (
            <span className={iconData?.className ? iconData?.className : ""}>
              <img
                src={iconData?.src}
                alt={iconData?.alt}
                className={`password_icon ${className ? className : ""}`}
                onClick={() => setShowHidePassword(!showHidePassword)}
              />
            </span>
          )}

          {radioChecks?.login && (
            <span className="radio-imgs">
              <img
                src={radioChecks?.src}
                alt={radioChecks?.alt}
                className={`radio-img ${className ? className : ""}`}
              />
            </span>
          )}
          {/* {login && (
            <img
              src={src}
              className={`radio-img ${className ? className : ""}`}
            />
          )} */}
        </label>
      </div>
    </>
  );
}
