import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import SenddMgsIcon from "../../../../assets/images/icons/sned_mgs_icon.svg";
import AttachFile from "../../../../assets/images/icons/attach_file.svg";
import User from "../../../../assets/images/icons/user.svg";
import crossIcon from "../../../../assets/images/remove.png";
import pdfIcon from "../../../../assets/images/file.png";
import docIcon from "../../../../assets/images/doc.svg";

import { userService } from "../../../../_services/User.services";
import Auth from "../../../../auth/Auth";
import { ChatContext } from "../../../../shared/ChatContext";
import { AppContext } from "../../../../shared/AppContext";

export default function MessageTextBox() {
  const fileRef = useRef();
  const secRef = useRef();
  const { thread } = useParams();
  const chatTimeIntervals = localStorage.getItem("chatTimeIntervals")?.length
    ? JSON.parse(localStorage.getItem("chatTimeIntervals"))
    : [];

  const [msgText, setMsgText] = useState("");
  const [file, setFile] = useState();
  const [btnPressed, setBtnPressed] = useState(false);
  // const location = useLocation();
  // const teacherLogin = localStorage.getItem("userInfo");

  // const [seconds, setSeconds] = useState(Number(localSecs) ? Number(localSecs) : 0);
  const [selectedFileType, setSelectedFileType] = useState("");
  const {
    showImg,
    setShowImg,
    threadInfo,
    settings,
    setthreadInfo,
    // newMsgSend,
    setNewMsgSend,
    seconds,
    setSeconds,
    // setChatTimeIntervals,
    getThread,
    // msgContent
  } = useContext(ChatContext);
  const { setSpinner, setToaster } = useContext(AppContext);


  // function emailValidation(email) {
  //   const regex =
  //     /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  //   return regex.test(email);
  // }

  useEffect(() => {
    getThread();
    //eslint-disable-next-line
  }, [settings]);

  const sendMsg = async (e) => {
    setSpinner(true)

    e.preventDefault();

    if (
      Auth?.user()?.role === "student" &&
      (!threadInfo?.enableStudentChat ||
        !threadInfo?.enableFileSharing ||
        threadInfo.studentChatPause > 0)
    ) {
      getThreadInfo("msgSend");
      setSpinner(false)
    }

    if (Auth?.user()?.role === "student" && !threadInfo?.enableStudentChat) {
      setSpinner(false)
      // alert("Ssh! teacher only mode. Message sending is off by the teacher");
      setToaster({ show: true, type: "danger", content: "Ssh! teacher only mode. Message sending is off by the teacher" });
      return;
    }

    let attachId = "";
    let thumbnailId = "";
    let msgType = "TEXT";
    let thumbnailImg;

    if (!msgText && !file) {
      setSpinner(false)
      setToaster({ show: true, type: "danger", content: "Please enter message!" });
      // alert("Please enter message!");
      return;
    }

    setBtnPressed(true);
    if (file) {
      if (file?.type?.startsWith("image/")) {
        msgType = "MEDIA_IMAGE";
      } else if (file.type.startsWith("video/")) {
        msgType = "MEDIA_VIDEO";
        thumbnailImg = await getThumbnailFromVideo(file);
        thumbnailId = await uploadAttackment(thumbnailImg);
      } else if (file.type.startsWith("audio/")) {
        msgType = "MEDIA_AUDIO";
      } else {
        msgType = "ATTACHMENT";
      }
      attachId = await uploadAttackment();
      if (!attachId) {
        setSpinner(false);
        // alert("No sharing please! File sharing off by the teacher");
        setToaster({ show: true, type: "danger", content: "No sharing please! File sharing off by the teacher" });

        setShowImg("");
        setFile();
        setTimeout(() => {
          setBtnPressed(false);
          setNewMsgSend(false);
        }, 0);
        return;
      };
      setSpinner(false)
    }

    // let params = {
    //   threadId: thread,
    //   body: {
    //     ...(location?.state?.type === "CLASS" ? { threadId: thread } : { classThreadId: thread }),
    //     content: msgText,
    //     type: msgType,
    //     ...(attachId ? { meta: { attachment: attachId } } : ""),
    //     ...(location?.state?.type === "CLASS" ? "" : {
    //       receiverUserId: emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
    //     }),
    //   }
    // };

    let params = {
      threadId: thread,
      body: {
        threadId: thread,
        content: msgText,
        type: msgType,
        ...(attachId
          ? {
            meta: {
              attachment: attachId,
              ...(thumbnailId ? { thumbnail: thumbnailId } : ""),
            },
          }
          : ""),
        // ...(location?.state?.type === "CLASS" ? "" : {
        //   receiverUserId: emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
        // }),
      },
    };

    // const response = await (location?.state?.type === "CLASS" ? userService?.createMessage(params) : userService?.createClassMessage(params));
    try {
      const response = await userService?.createClassMessage(params);

      if (response?.status >= 200 && response?.status <= 299) {
        // if (Auth?.user()?.role === "student" && threadInfo?.studentChatPause > 0) {
        //   setSeconds(Number(threadInfo?.studentChatPause))
        // }
        setSpinner(false)
        if (msgType === "TEXT" || msgType === "MEDIA_AUDIO") {
          setNewMsgSend(true);
        }
        setMsgText("");
        setShowImg("");
        setFile();

        if (
          Auth?.user()?.role === "student" &&
          Number(threadInfo?.studentChatPause) > 0
        ) {
          const eleIndex = chatTimeIntervals.findIndex(
            (ele) => ele.id === thread
          );
          eleIndex === -1
            ? chatTimeIntervals.push({
              id: thread,
              time: Number(threadInfo?.studentChatPause),
            })
            : (chatTimeIntervals[eleIndex].time = Number(
              threadInfo?.studentChatPause
            ));
          localStorage.setItem(
            "chatTimeIntervals",
            JSON?.stringify(chatTimeIntervals)
          );
          setSeconds(threadInfo?.studentChatPause);
        }
      }
      if (response.status >= 400 && response.status <= 499) {
        const data = await response.json();
        setMsgText("");
        getThreadInfo("msgSend");
        setSpinner(false)
        // alert(
        //   data?.error === "You are blocked"
        //     ? "Oops! you did a mistake. You are blocked"
        //     : data?.error
        // );
        setToaster({
          show: true, type: "danger", content: data?.error === "You are blocked"
            ? "Oops! you did a mistake. You are blocked"
            : data?.error
        });

        // if (JSON.parse(teacherLogin)?.role === "student") {
        //   alert(
        //     "Oops! you did a mistake. You are blocked"
        //   );
        //   // alert(
        //   //   data?.error === "You are blocked"
        //   //     ? "Oops! you did a mistake. You are blocked"
        //   //     : data?.error
        //   // );
        // } else {
        //   alert(
        //     data?.error === "You are blocked"
        //       ? "Oops! you did a mistake. You are blocked, talk to your service manager for immediate help."
        //       : data?.error
        //   );
        // }
      }
      if (response?.status === 401) {
        localStorage.clear();
      };
      setTimeout(() => {
        setBtnPressed(false);
        setNewMsgSend(false);
      }, 0);

    } catch (error) {
      setSpinner(false)
      setBtnPressed(false);
      setNewMsgSend(false);
    }
    setSpinner(false);
  };

  const clearFile = () => {
    fileRef.current.value = "";
    setShowImg();
    setFile();
    setSelectedFileType();
  };

  const selectAttachment = (file) => {
    if (!file[0].type) {
      // alert("Please select valid file!");
      setToaster({ show: true, type: "danger", content: "Please select valid file!" });
      clearFile();
      return;
    } else if (
      file[0]?.name?.match(
        /\.(exe|apk|html|htm|ini|cdr|php|jsp|asp|sql|msi|rar|zip|7zip)$/
      )
    ) {
      // alert("Please select valid file!");
      setToaster({ show: true, type: "danger", content: "Please select valid file!" });
    } else if (file[0]?.size > 25e6) {
      // alert("Please upload file lesser then 25 MB");
      setToaster({ show: true, type: "danger", content: `Please upload file lesser then 25 MB` });

    } else {
      setFile(file[0]);
      let a = URL.createObjectURL(file[0]);
      setShowImg(a);
      setSelectedFileType(file[0]?.type);

      // if (file[0].type === "application/pdf") {
      //   setShowImg();
      // }
    }
  };

  const uploadAttackment = async (videoThumb = "") => {
    setSpinner(true);
    try {
      let params = {
        name: file.name,
        type: videoThumb ? videoThumb.type : file.type,
        threadId: thread,
        body: videoThumb ? videoThumb : file,
      };
      const response = await userService?.uploadAttachment(params);

      if (response?.status >= 200 && response?.status <= 299) {
        const data = await response.json();
        setSpinner(false);
        return data?.id;
      } else {
        setSpinner(false);
        // console.log(response);
      }
      if (response?.status === 401) {
        localStorage.clear();
      };
    } catch (error) {
      setSpinner(false);
      return error;
    }
  };

  const getThreadInfo = async (type = "") => {
    setSeconds(0);
    const response = await userService?.getThread({ thread: thread });

    if (response?.status >= 200 && response?.status <= 299) {
      const data = await response.json();

      // check if thread in chatTimeIntervals array and slow mode on then setTime.
      if (!type) {
        let thredIndx = chatTimeIntervals.findIndex((ele) => ele.id === thread);

        if (Number(data?.thread?.studentChatPause) > 0 && thredIndx !== -1) {
          setSeconds(chatTimeIntervals[thredIndx].time);
        } else {
          setSeconds(0);
          // check if slow mode off and thread exist in array then set time 0.
          if (thredIndx !== -1) {
            chatTimeIntervals[thredIndx].time = 0;
            localStorage.setItem(
              "chatTimeIntervals",
              JSON?.stringify(chatTimeIntervals)
            );
          }
        }
      }
      setthreadInfo(data?.thread);
    }
    if (response?.status === 401) {
      localStorage.clear();
    };
  };

  // get thumbnail fron video
  const getThumbnailFromVideo = (file) => {
    if (!file.type.startsWith("video/")) {
      return;
    }

    return new Promise((resolve, reject) => {
      const video = document.getElementById("videoView");
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, 150, 150);
      ctx.canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    });
  };

  useEffect(() => {
    getThreadInfo();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (seconds > 0 && threadInfo.studentChatPause > 0) {
      setTimeout(() => {
        setSeconds((prev) => prev - 1);
      }, 1000);
    }
    //eslint-disable-next-line
  }, [seconds]);

  useEffect(() => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();

      let thredIndx = chatTimeIntervals.findIndex((ele) => ele.id === thread);

      if (threadInfo?.studentChatPause && thredIndx !== -1) {
        chatTimeIntervals[thredIndx].time = seconds;
        localStorage.setItem(
          "chatTimeIntervals",
          JSON?.stringify(chatTimeIntervals)
        );
      }
      return (ev.returnValue = "Do you really want to close?");
    });
    //eslint-disable-next-line
  }, [seconds]);

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendMsg(e);
    }
  };

  // useEffect(() => {
  //   setSpinner(true)
  //   if (msgContent?.length === msgContent?.length + 1) {
  //     setSpinner(false)
  //   }
  // }, [msgContent])

  console.log("seconds === 0", seconds === 0, "(seconds === 0 || isNaN(seconds)", ((seconds === 0 || isNaN(seconds))), "isNaN(seconds)", isNaN(seconds));
  return (
    <React.Fragment>
      <div className="input_send  ">
        <div className="chat_input position-relative">
          {showImg && (
            <div className="media-box mx-4  mb-4 py-4 border-bottom border-dark position-relative">
              {selectedFileType !== "application/pdf" &&
                !selectedFileType.includes("video") && (
                  <img
                    className="img-fluid media-content"
                    // file?.type?.startsWith('video/') ? `${process.env.PUBLIC_URL}/video-placeholder.webp` :
                    src={showImg}
                    alt=""
                  />
                )}

              {selectedFileType === "application/pdf" && (
                <img className="img-fluid media-content" src={pdfIcon} alt="" />
              )}

              {selectedFileType?.split("/")[0] !== "image" &&
                selectedFileType !== "application/pdf" &&
                selectedFileType?.split("/")[0] !== "video" && (
                  <img
                    className="img-fluid media-content"
                    src={docIcon}
                    alt=""
                  />
                )}

              {selectedFileType.includes("video") && (
                <video id="videoView" className="text-center" width="300" height="220" controls>
                  <source src={URL.createObjectURL(file)} type="video/mp4" />
                  <source src={URL.createObjectURL(file)} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              )}

              {/* <video className="img-fluid media-content" src={showImg}></video> */}
              <span className="crossimg">
                <img src={crossIcon} alt="cross icon" onClick={clearFile} />
              </span>
              <p className="text-center"> {file?.name}</p>
            </div>
          )}
          <div className="input_prof_icon">
            <img src={User} alt="user" />
          </div>
          {Auth?.user()?.role === "student" &&
            !threadInfo?.enableFileSharing ? (
            ""
          ) : (
            <div className="input_file_icon">
              {/* {threadInfo?.enableFileSharing && */}
              <label htmlFor="fileUploadMgs">
                <img src={AttachFile} alt="attach file" />
              </label>
              {/* } */}
              <input
                type="file"
                ref={fileRef}
                id="fileUploadMgs"
                style={{ display: "none" }}
                onChange={(e) => selectAttachment(e.target.files)}
              />
            </div>
          )}

          {/* {
               if (seconds > 0 && Auth?.user()?.role === "student") {
                  return "Hey! take a breath! go slow! slow mode on"
                }
                else if (!threadInfo?.enableStudentChat) {
                  return "Ssh! teacher only mode"
                }
                else {
                  return "Hey! take a breath! go slow! slow mode on"
                }
} */}

          <form onSubmit={sendMsg}>
            <textarea
              className="w-100 textarea_send"
              rows="1"
              placeholder={
                (seconds > 0 && Auth?.user()?.role === "student" && threadInfo?.studentChatPause > 0)
                  ? "Hey! take a breath! go slow! slow mode on"
                  : Auth?.user()?.role === "student" &&
                    !threadInfo?.enableStudentChat
                    ? "Ssh! teacher only mode"
                    : "Add a comment…"
              }
              value={msgText}
              onChange={(e) => setMsgText(e.target.value)}
              disabled={Auth?.user()?.role === "student" && (!threadInfo?.enableStudentChat || seconds > 0) && threadInfo?.studentChatPause > 0}
              onKeyDown={onEnterPress}
            />
            {/* <input
              className="w-100 textarea_send"
              rows="1"
              placeholder={seconds > 0 && Auth?.user()?.role === "student" ? "Hey! take a breath! go slow! slow mode on" : "Add a comment…"}
              value={msgText}
              onChange={(e) => setMsgText(e.target.value)}
              disabled={Auth?.user()?.role === "student" && (!threadInfo?.enableStudentChat || seconds > 0)}
            /> */}
          </form>
        </div>
        {/* {(Auth?.user()?.role === "student" &&
          threadInfo?.studentChatPause > 0 &&
          seconds > 0) ? "true" : "false"} */}
        {(Auth?.user()?.role === "student" &&
          threadInfo?.studentChatPause > 0 &&
          seconds > 0) ?
          <div
            className={`send_content  ${btnPressed ? 'pointerNone' : 'pointer'}`} ref={secRef}
            {...((seconds === 0 || isNaN(seconds))) && { onClick: (e) => sendMsg(e) }}>

            {(seconds > 0 && threadInfo?.studentChatPause > 0) ?
              seconds :
              <>
                true
                {/* // --{seconds}--{tempVar}-- */}
                <img src={SenddMgsIcon} alt="send file your" />
              </>
            }
          </div>
          :
          <div
            className={`send_content  ${btnPressed ? 'pointerNone' : 'pointer'}`}
            onClick={(e) => sendMsg(e)} >
            <img src={SenddMgsIcon} alt="send file my" />

          </div>}
        {/* <div className={`send_content  `} onClick={(e) => sendMsg(e)}>
          <img src={SenddMgsIcon} alt="send file" />
        </div> */}
      </div>
    </React.Fragment>
  );
}
