import React, { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import io from "socket.io-client";
import Auth from "../auth/Auth";
import { userService } from "../_services/User.services";
export const ChatContext = createContext();

export default function ChatContextProvider({ children }) {
  const [msgContent, setMsgContent] = useState([]);
  const [showImg, setShowImg] = useState("");
  const [threadInfo, setthreadInfo] = useState();
  const [settings, setSettings] = useState();
  const [newMsgSend, setNewMsgSend] = useState(false);
  const [classBlockedUsers, setClassBlockedUsers] = useState({ classId: '', blockedStudents: [] });
  const [chatTimeIntervals, setChatTimeIntervals] = useState([])

  const [seconds, setSeconds] = useState(0);
  const { thread } = useParams()

  const getThread = async () => {
    const response = await userService?.getThread({ thread: thread });

    if (response?.status >= 200 && response?.status <= 299) {
      const data = await response.json();
      setthreadInfo(data?.thread);
    };
    if (response?.status === 401) {
      localStorage.clear();
    };
  };
  useEffect(() => {
    if (thread) {
      getThread()
    };
    //eslint-disable-next-line
  }, [thread])

  useEffect(() => {
    const socket = io("https://chat.prathamedu.com/chat", {
      // const socket = io("192.168.88.58:3000", {
      transports: ["websocket"],
      upgrade: false,
      auth: (cb) => {
        cb({
          // token: userService?.emailValidation(String(Auth?.user()?.user_name)) ? btoa(String(Auth?.user()?.user_name)) : String(Auth?.user()?.user_name),
          token: Auth.token(),
          as: Auth?.user()?.role.toLowerCase(),
        });
      },
    });

    if (socket) {

      socket.on("connect_error", (err) => {
        if (err.message === "invalid credentials") {
          socket.auth.token = "efgh";
          socket.connect();
        }
      });

      socket.on('message', (data) => {
        setMsgContent(prevContent => [...prevContent, { ...data.message }])
      })

      socket.on("disconnect", () => {
      });

      socket.on("connect", (data) => {
      });

      socket.on("thread:setting", (data) => {
        setSettings(data)
      });

      socket.on("message:deleted", (data) => {
        setMsgContent(prevContent => prevContent.filter(ele => ele._id !== data.message._id));
      });
    }

    //eslint-disable-next-line
  }, []);

  return <ChatContext.Provider
    value={{
      msgContent,
      setMsgContent,
      showImg,
      setShowImg,
      threadInfo,
      setthreadInfo,
      settings,
      setSettings,
      newMsgSend,
      setNewMsgSend,
      classBlockedUsers,
      setClassBlockedUsers,
      seconds,
      setSeconds,
      chatTimeIntervals,
      setChatTimeIntervals,

      getThread
    }}>
    {children}
  </ChatContext.Provider>;
}
