import React, { useState, useEffect, useContext } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowLeftBlack from "../../../assets/images/icons/arrow_left_black.svg";
// import NewFile from "../../../assets/images/icons/new_file.svg";
import User from '../../../../src/assets/images/icons/user.png';
import { userService } from "../../../_services/User.services";
import { AppContext } from '../../../shared/AppContext';

function BlockUSerMode({ mode }) {

    const navigate = useNavigate();
    const location = useLocation()
    const { thread } = useParams();
    const [originalList, setOriginalList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const { setSpinner } = useContext(AppContext);

    const getThreadUsersList = async () => {
        setSpinner(true)
        try {
            const response = await userService?.getThreadUsersList(thread);
            response.json().then(res => {
                let usersArray = res.students.map(el => {
                    const ele = Object.assign({}, el);
                    ele.is_block = 0;
                    if (res.thread.blockedStudents.indexOf(ele.userId) !== -1) {
                        ele.is_block = 1;
                    }
                    return ele;
                });
                // if (location?.state?.mode === "unblock") {
                //     let unblockUserAry = usersArray?.filter(itm => itm.is_block === 0)

                //     setUsersList(unblockUserAry)
                // }
                // else {

                //     let unblockUserAry = usersArray?.filter(itm => itm.is_block === 1)
                //     setUsersList(unblockUserAry)
                // }
                setUsersList(usersArray.sort((a, b) => b.is_block - a.is_block))
                setOriginalList(usersArray.sort((a, b) => b.is_block - a.is_block));
                setSpinner(false);

                if (res?.status === 401) {
                    localStorage.clear();
                };
            })
        }
        catch (error) {
        }
    }

    const changeUserStatus = async (user, index) => {

        try {
            const response = await userService?.updateClassUserStatus(thread, user);

            const newArr = [...usersList];
            newArr[index].is_block = user.is_block ? 0 : 1;
            setUsersList(newArr);

        }
        catch (error) {
        }
    }

    const searchUser = (e) => {
        const arr = [];
        if (!e.target.value.length) {
            setUsersList(originalList);
            return;
        }

        const orrArr = [...originalList];
        const searchArray = orrArr.filter(ele => {

            if (ele?.displayName?.toLowerCase().includes(e?.target?.value?.toLowerCase()) || ele?.userId?.toLowerCase()?.includes(e?.target.value?.toLowerCase())) {
                return ele;
            }
        })
        setUsersList(searchArray);

    }

    useEffect(() => {
        getThreadUsersList();
        //eslint-disable-next-line
    }, []);
    return (
        <>
            <div className="chat_box">
                <div className="row chat_header align-items-center">
                    <div className="col-12 left_cont d-flex align-items-center chat_head_gap">
                        <img onClick={() => navigate(-1)} src={ArrowLeftBlack} alt="arrow left black" />
                        <h2 className="ht mb-0">Blocked Users</h2>
                    </div>
                </div>
                <div className="chat_search">
                    <input
                        type="text"
                        className="search_icon"
                        placeholder="Search"
                        onChange={(e) => searchUser(e)}
                    />
                </div>
                <div className="chat_card_container mt-2t">

                    {usersList?.map((ele, index) => <div className="chat_card chat_boxS chat_Pbt brd_white" key={index}>
                        <div className="card_cont">
                            <div className="row">
                                <div className="col-8 d-flex align-items-center">
                                    <div className="card_img">
                                        <img src={User} alt="" className="small_user-icon" />
                                    </div>
                                    <div className="card_txt">
                                        <h4 className="ulive mb-0">{ele.displayName}</h4>
                                        <p style={{ fontSize: "12px" }}>{ele.userId}</p>
                                    </div>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-end">
                                    <button className="btn btn_unblock" onClick={() => changeUserStatus(ele, index)}>{ele.is_block ? 'Unblock' : 'Block'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}


                    {/* <div className="chat_card chat_boxS chat_Pbt brd_white">
                        <div className="card_cont">
                            <div className="row">
                                <div className="col-8 d-flex align-items-center">
                                    <div className="card_img">
                                        <img src={User} alt="" className="small_user-icon" />
                                    </div>
                                    <div className="card_txt">
                                        <h4 className="ulive mb-0">Rahul Roy</h4>
                                    </div>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-end">
                                    <button className="btn btn_unblock">Unblock</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chat_card chat_boxS chat_Pbt brd_white">
                        <div className="card_cont">
                            <div className="row">
                                <div className="col-8 d-flex align-items-center">
                                    <div className="card_img">
                                        <img src={User} alt="" className="small_user-icon" />
                                    </div>
                                    <div className="card_txt">
                                        <h4 className="ulive mb-0">Geeta sharma</h4>
                                    </div>
                                </div>
                                <div className="col-4 d-flex align-items-center justify-content-end">
                                    <button className="btn btn_unblock">Unblock</button>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>

            </div>
        </>
    )
}

export default BlockUSerMode
