import React, { useState, useEffect } from "react";
import Addmember from "../../../../src/assets/images/icons/addmember.svg";
import Plus from "../../../../src/assets/images/icons/plus.svg";
import Setting from "../../../../src/assets/images/icons/setting.svg";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";

import InputField from "../../utils/forms/InputField";
// import Header from "../header/Header";
import { ChatCard } from "../../utils/cards/ChatCard";
import Auth from "../../../auth/Auth";
import { userService } from "../../../_services/User.services";
import { useContext } from "react";
import { AppContext } from "../../../shared/AppContext";

// let apiToken =
//   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMzYwZmUyZjI1NGY4YmZkOTIyMjU5NjA0YjY4ZTAyMDczMzBmMWYwZWM4ODcyMDdmZmI0NjFiN2E4OTliYmU3OWY4NWNlYmIxNjEwYWM1OTgiLCJpYXQiOjE2NDQ0MDIwODIuMTU0NjEzLCJuYmYiOjE2NDQ0MDIwODIuMTU0NjE5LCJleHAiOjE2NzU5MzgwODIuMTA4Nzk3LCJzdWIiOiIxOSIsInNjb3BlcyI6WyJ0ZWFjaGVyIl19.C90xmeF8wdPM6J2QvQe0GcKPWmwfWSZY8lkFiacDMzGE4vfTKYChCxcZ9Bp1mCMkdiAX1c61Qyl2eTvgZaUSQyRCa7a368GpmYhrpeLG4qBUAfOJmvEj3KnptLsikRDpS_IpOK7dHHMEcvbGaTVRTh0WLt9rzJD2VuD5JLdW7renKxTkVvqbG5g8Ztb5yvJwfj3ofRe1A-7W2ICGULDXk4KSQSrVpfva3a58oKcSr17EWSBvzmBA8Pf79g-hesgxBEhomzel7lf1MD8U7YIqZMl-0O5XdbiDoB4OypKDbxTBi2CUSXFU6HHsiJ39x1ZvFotlzxPS8JaKAN5sDFagR-HrB-HQyWG-jd9-N6GJyBY9tCA7gyYYpEEJNZ6Fbm8BPuJxQ25Q79W8wlKwyfLRYnW1WWKDIOZjcRvX0dnWPqVzcSOa_nBkTGIikHDxA4cMuSS-09IS0uyNZBmEqaOKNGxfSUbpyKpinZ8Z_GhZVc1fg9rRQuv6Uq5QMCoZogS99kVYQYa6Xl2uH2fUMlv2TWyytwhW40ytLADPXS-KIWFlQlNdtlHXIKd19zQgQ9j2W6ksJmTuzkPsKjn2iAFP_EB6SJ5TlXgg3pvLbnB17zMk9DHrNJlXvOrTcHR299LnMbTd8vJoHAfozx7irdBsVPzZ2nKQkAYa_Xx6j4cHT4M";

function LiveClass() {
  const [key, setKey] = useState("liveclass");

  const [liveClasses, setLiveClasses] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [searchStr, setSearchStr] = useState("")
  const { setSpinner } = useContext(AppContext);

  const getClasses = async () => {
    setSpinner(true);
    const response = await userService?.getClasses({ type: "CLASS" });
    if (response?.status >= 200 && response?.status <= 299) {
      const data = await response.json();
      setSpinner(false)
      setLiveClasses(data?.threads);
    }
    if (response?.status === 401) {
      localStorage.clear();
    }
  };

  const searchClass = (value) => {
    if (liveClasses?.length) {
      let filterData = liveClasses?.filter(itm => itm?.name?.toLowerCase()?.includes(value?.toLowerCase()) || itm?.userId?.toLowerCase()?.includes(value?.toLowerCase()));

      setFilterData(filterData);
      //console.log("filterData", filterData);
    }
  }

  useEffect(() => {
    getClasses();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilterData(liveClasses);
  }, [liveClasses])

  useEffect(() => {
    searchClass(searchStr);
    //eslint-disable-next-line
  }, [searchStr]);
  return (
    <>
      <div className="chat_box chat-tabs">
        <div className="row chat_header align-items-center">
          <div className="col-11 left_cont">
            <h2 className="ht mb-0">{Auth?.user()?.name + (Auth?.user()?.role === "Teacher" ? " (Faculty)" : (` (${Auth?.user()?.user_name})`))}</h2>
          </div>
          <div className="col-1 right_cont d-flex justify-content-end pr-4 align-items-start">
            {/* {Auth.user().role !== 'student' && <Link to="select-participants">
              <img src={Plus} alt="plus icon" className="small_iconS" />
            </Link>
            } */}
            <Link to="setting">
              <img src={Setting} alt="setting icon" className="small_iconS" />
            </Link>
          </div>
        </div>
        {/* tabs html */}

        <div className="chat_tab mt-2t">
          <Tabs
            defaultActiveKey="liveclass"
            id="uncontrolled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            // className="mb-3 grid-3"
            className="mb-3 grid-1"
          >
            <Tab eventKey="liveclass" title="Live Class">
              <div className="chat_search">
                <div className="input-box mb-5 mt-4">
                  <input type={"text"} onChange={(e) => {
                    setSearchStr(e.target.value)
                    // searchClass(e.target.value)
                  }} className={`roleSelector search_icon`} placeholder="Search" />
                </div>
              </div>
              {/* <TabLiveClass /> */}
              <div className="live-class tab-listing">
                <ChatCard type="CLASS" liveClasses={filterData} setLiveClasses={setLiveClasses} isHistory={false} />
              </div>

              {/* <div className="addMember">
                <Link to="contacts">
                  <img src={Addmember} alt="add icon" />
                </Link>
              </div> */}
            </Tab>
            {/* <Tab eventKey="Groups" title="Groups">
              <div className="chat_search">
                <InputField
                  type="text"
                  className="search_icon"
                  placeholder="Search"
                  bindField={bindField}
                  name="searchBar"
                />
              </div>
              <div className="live-groups tab-listing">
                <ChatCard type="GROUP" />
              </div>
            </Tab>
            <Tab eventKey="Chat" title="Chat">
              <div className="chat_search">
                <InputField
                  type="text"
                  className="search_icon"
                  placeholder="Search"
                  bindField={bindField}
                  name="searchBar"
                />
              </div>
              <div className="live-chats tab-listing">
                <ChatCard type="SINGLE" />
              </div>
            </Tab> */}
          </Tabs>
        </div>

        {/* tabs html */}
      </div>
    </>
  );
}

export default LiveClass;
