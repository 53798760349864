import React, { useContext, useEffect, useState } from 'react'
import { ChatCard } from '../../utils/cards/ChatCard'
import { userService } from '../../../_services/User.services';
import Calender from "../../../assets/images/calendar.svg";
import ArrowLeft from "../../../assets/images/icons/arrow_left_black.svg";
import moment from 'moment';
import { AppContext } from '../../../shared/AppContext';
import { useNavigate } from 'react-router-dom';

export default function ChatHistory() {
  const navigate = useNavigate()
  const { setSpinner, setIsChatHistory,
    historyFromDate, setHistoryFromDate,
    historyToDate, setHistoryToDate } = useContext(AppContext);
  const [liveCLasses, setLiveClasses] = useState([])
  // const [] = useState(new Date());

  const getClasses = async () => {
    setSpinner(true);
    const response = await userService?.getClasses({
      type: "CLASS",
      history: true,
      to: moment(historyToDate).format("YYYY-MM-DD"),
      from: moment(historyFromDate).format("YYYY-MM-DD")
    });
    if (response?.status >= 200 && response?.status <= 299) {
      const data = await response.json();
      setSpinner(false)
      setLiveClasses(data?.threads);
    }
    if (response?.status === 401) {
      localStorage.clear();
    }
  };

  // const setDate = (newDate) => {
  //   const date = newDate || new Date();
  //   setHistoryDate(
  //     date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
  //   );
  // };

  // const getPreviousDate = () => {
  //   const currentDayInMilli = new Date(historyDate).getTime();
  //   const oneDay = 1000 * 60 * 60 * 24;
  //   const previousDayInMilli = currentDayInMilli - oneDay;
  //   const previousDate = new Date(previousDayInMilli);

  //   if (historyDate) {
  //     if (historyDate === previousDate) {
  //       setDate(historyDate);
  //     } else {
  //       setDate(previousDate);
  //       // setHistoryDate(previousDate)
  //     }
  //   }

  //   // setDate(previousDate);
  // };

  // const getNextDate = () => {
  //   const currentDayInMilli = new Date(historyDate).getTime();
  //   const oneDay = 1000 * 60 * 60 * 24;
  //   const nextDayInMilli = currentDayInMilli + oneDay;
  //   const nextDate = new Date(nextDayInMilli);
  //   setDate(nextDate);
  //   // setHistoryDate(nextDate);
  // };


  // useEffect(() => {
  //   getClasses();
  //   //eslint-disable-next-line
  // }, [historyFromDate, historyToDate]);

  useEffect(() => {
    getClasses();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsChatHistory(true); //eslint-disable-next-line
  }, []);

  return (
    <div className='chat-section'>

      <div className="chat_box chat-tabs pt-0">
        <div className='chat-history'>
          <img src={ArrowLeft} alt="" onClick={() => { navigate(-1) }} />
          <h2>Chat History</h2>
        </div>
        <div className='date-history'>
          <div className='from'>
            {/* <label htmlFor=""> From</label> */}
            <span className='d-flex'>
              <input type="date" style={{ backgroundImage: `url(${Calender})` }} onChange={(e) => setHistoryFromDate(e.target.value)} />
              {moment(historyFromDate).format("DD MMM, YYYY")}
            </span>
          </div>
          <div className='hyp-dash'>
            -
          </div>
          <div className='to'>
            {/* <label htmlFor=""> To</label> */}
            <span className='d-flex'>
              {moment(historyToDate).format("DD MMM, YYYY")}
              <input type="date"
                style={{ backgroundImage: `url(${Calender})` }}
                onChange={(e) => setHistoryToDate(e.target.value)}
                max={new Date().toISOString().split('T')[0]}
              />
            </span>
          </div>
          <div className="chat-header-btn">
            <button type="button" className='chat-btn' onClick={getClasses}>Go</button>
          </div>

        </div>
        <div className="live-class tab-listing">
          <ChatCard type="CLASS" liveClasses={liveCLasses} setLiveClasses={setLiveClasses} isHistory={true} />
        </div>
      </div>
    </div>
  )
}
