import React from "react";
// import User from "../../../assets/images/icons/user.png";
// import ArrowLeftBlack from "../../../assets/images/icons/arrow_left_black.svg";
// import { Link } from "react-router-dom";
import Header from "../header/Header";
import { ChatCard } from "../../utils/cards/ChatCard";
function Contacts() {
  return (
    <div className="chat_box">
      {/* <div className="row chat_header align-items-center">
        <div className="col-12 left_cont d-flex align-items-center chat_head_gap">
          <Link to="/">
            {" "}
            <img src={ArrowLeftBlack} alt="arrow left black" />
          </Link>
          <h2 className="ht mb-0">Contacts</h2>
        </div>
      </div>
      <div className="chat_search">
        <input type="text" className="search_icon" placeholder="Search" />
      </div>
      <div className="chat_card_container bottombtn">
        <div className="chat_card chat_boxS chat_Pbt brd_white">
          <div className="card_cont">
            <div className="row">
              <div className="col-12 d-flex align-items-center">
                <div className="card_img">
                  <img src={User} alt="" className="small_user-icon" />
                </div>
                <div className="card_txt">
                  <h4 className="ulive mb-0">Tiger Queen 1</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="chat_card chat_boxS chat_Pbt brd_white">
          <div className="card_cont">
            <div className="row">
              <div className="col-12 d-flex align-items-center">
                <div className="card_img">
                  <img src={User} alt="" className="small_user-icon" />
                </div>
                <div className="card_txt">
                  <h4 className="ulive mb-0">Tiger Queen</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <Header />
      <div className="contact-listing tab-listing">
        <ChatCard />
      </div>
    </div>
  );
}

export default Contacts;
